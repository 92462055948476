import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import toBack from '../../assets/img/icons/Arrow-black.svg';
import toNext from '../../assets/img/icons/Arrow-gray.svg';
import { showAccountInfo } from '../../actions/headerActions';
import { getCategories, validateUserToken } from '../../actions/loginActions';
import { getExternalUrl, loader } from '../../helpers';

import Header from '../../components/Header/Header';

import layoutStyles from '../../routes/fullLayout.module.css';
import styles from './CategorySelect.module.scss';
import 'reactjs-popup/dist/index.css';
const MySwal = withReactContent(Swal);

const DynamicRedirect = ({ children, isExternal, category }) => {
  const counter = useSelector(state => state);

  if (isExternal) {
    const Authorization = counter?.loginReducer?.Authorization;

    const handleClick = async (e) => {

      if (category.title === "Historia clínica") {
        e.preventDefault();
        try {
          const response = await validateUserToken(Authorization);
          if (response.status === 200) {
            window.location.href = getExternalUrl({ loginReducer: counter.loginReducer, category })
          } else {
            MySwal.fire({
              icon: 'error',
              title: 'Error',
              text: response.message,
            });
          }
        } catch (error) {
          console.error('Error while validating token:', error);
          MySwal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Something went wrong. Please try again later.',
          });
        }
      }
    };

    return (
      <a
        href={getExternalUrl({ loginReducer: counter.loginReducer, category })}
        onClick={handleClick}
        style={{ textDecoration: 'none' }}
      >
        {children}
      </a>
    );
  }

  return children;
};

function CategorySelect() {
  const counter = useSelector(state => state);
  const history = useHistory();
  const categories = counter.loginReducer?.newCategories;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!counter.loginReducer.Authenticated) {
      history.push('/login');
    }
  }, [counter.loginReducer.Authenticated, history]);

  useEffect(() => {
    dispatch(getCategories(counter.loginReducer.currentAccount.profile.id));
  }, [counter.loginReducer.currentAccount.profile.id, dispatch]);

  const handleClick = ({ category, alternative }) => {
    history.push({
      pathname: '/' + category?.url + '/inicio',
      state: {
        group: alternative,
        catDetail: {
          ...category,
          title: 'Módulo de ' + category.title,
        },
      },
    });
  };

  return (
    <div className={`${layoutStyles.fullLayout}  ${styles.bgImage} `}>
      {categories === undefined && counter.loginReducer.loadingCategories && loader}
      <Header isAuth={true}></Header>
      <div className={`${layoutStyles.fullLayoutBody} `}>
        <div
          className={`p-5 ${layoutStyles.fullLayoutWorkArea}`}
          onClick={() => dispatch(showAccountInfo(false))}
        >
          <div className={`mt-2 ${styles.marginRight}`}>
            <Link to={`/accountselect`}>
              <img alt='goBack' src={toBack}></img>
            </Link>
          </div>
          <div className={`w-100`}>
            {categories?.length > 0 ? (
              categories.map((e, i) => {
                return (
                  <Row key={i} className=''>
                    <label className={`${styles.TitleBar} ${styles.marginRight}`}>
                      {e.description}{' '}
                    </label>
                    {e.data?.map((mod, i) => {
                      const isExternal = counter?.loginReducer?.all_categories?.find(
                        cat => cat?.name === mod?.title,
                      )?.isExternal;

                      return (
                        <>
                          <Col key={`Module${i}`} xs={4}>
                            <DynamicRedirect isExternal={isExternal} category={mod}>
                              <Card
                                onClick={() => {
                                  if (!isExternal) {
                                    handleClick({ e, category: mod, alternative: e.description });
                                  }
                                }}
                                className={`${styles.flex1} rounded-5 mb-2`}
                              >
                                <Row>
                                  <Col xs={3}>
                                    <img src={mod.img} className={styles.classIcon} alt='title' />
                                  </Col>
                                  <Col xs={7}>
                                    <p className={`my-auto ${styles.classTitle}`}>{mod.title}</p>

                                    <p className={`my-auto ${styles.classSubTitle}`}>
                                      {mod.subtitle?.length >= 55
                                        ? `${mod.subtitle.slice(0, 52)}...`
                                        : mod.subtitle}
                                    </p>
                                  </Col>
                                  <Col className='d-flex pb-3' xs={2}>
                                    <img
                                      src={toNext}
                                      className={`${styles.classArrow} pt-3`}
                                      alt='next'
                                    />
                                  </Col>
                                </Row>
                              </Card>
                            </DynamicRedirect>
                          </Col>
                        </>
                      );
                    })}
                  </Row>
                );
              })
            ) : (
              <div key={0} className={'mt-2'}>
                <span>Este usuario no tiene módulos accesibles</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CategorySelect;
