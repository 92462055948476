/* eslint-disable no-case-declarations */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
// --------------- components 🏴󠁧󠁢󠁥󠁮󠁧   ----------------//
import { Checkbox } from '@material-ui/core';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { genericDownloadExcel } from '../../helpers';
import { convertDateToLatinFormat } from '../../helpers/convertDateFormat';
import ModalNew from '../Layouts/ModalNew';
// // --------------- icons 💥  ----------------//

import moment from 'moment';
import OrdLupa from '../../assets/img/icons/OrdLupa.svg';
import Alert from '../../assets/img/icons/alert-circle-orange.svg';
import BackArrow from '../../assets/img/icons/atras-icon.svg';
import CanecaGris from '../../assets/img/icons/canecaGris.svg';
import CheckNew from '../../assets/img/icons/check2.svg';
import Excel from '../../assets/img/icons/excel.svg';
import Check2 from '../../assets/img/icons/extraBtnCheck.svg';
import {
  default as Reject,
  default as rejectedIcon,
} from '../../assets/img/icons/extraBtnRedX.svg';
import addToList from '../../assets/img/icons/grayAdd.svg';
import LupaBlue from '../../assets/img/icons/lupa.svg';
import note from '../../assets/img/icons/ordSugess.svg';
import threeDots from '../../assets/img/icons/threeDots.svg';

//-------------- scss styles 😄   --------------
import GenericTableScroll from '../../components/Layouts/GenericTableScroll';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import IndividualStyles from '../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';
import { customSelectNewDark } from '../Layouts/react-select-custom';
import CustomPopupExtend from '../Popup/customPopUpExtends';
import Styles from './generatePayroll.module.scss';

///--------------- Actions
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Swal from 'sweetalert2';
import { useGetMethod, usePostMethod } from '../../Hooks';
import { OrdSlideDownButton } from '../../OrderingModule/OrderingComponents/OrdSlideDownButton';
import {
  creatPayroll,
  getListPayrollAuthorization,
} from '../../actions/accountingMovementsActions';
import { getPdfTest } from '../../actions/consultAction';
import downGrey from '../../assets/img/icons/desplegarGris.svg';
import { API_VERSION, URL_GATEWAY } from '../../helpers/constants';
import { convertFilterToString } from '../../helpers/convertToFilter';
import { customSwaltAlert } from '../../helpers/customSwaltAlert';
import {
  convertMoneyFormat,
  downloadFilePdf,
  formatter,
  loader,
  message,
  validateEmptyString,
} from '../../helpers/helpers';
import { Title } from '../../shared';
import SelectComponent from '../SelectComponent/SelectComponent';
import DetailEmployee from './DetailEmployee';
import { ImbalanceWarningModal } from './ImbalanceWarningModal';

const scrollPositions = {
  main: 0,
  table: 0,
};

function DetailPayrollTemplate(props) {
  const dispatch = useDispatch();
  const storage = useSelector(state => state);
  const [trigger, setTrigger] = useState(1);
  const [triggerNews, setTriggerNews] = useState(1);
  const [triggerTable, setTriggerTable] = useState(1);
  const [showImbalanceModal, setShowImbalanceModal] = useState(false);
  const { trigger: getSelectsData, load: loaderGetSelectsData } = useGetMethod();
  const counter = useSelector(state => state);
  const { trigger: postDocExcel, load: loaderPostDocExcel } = usePostMethod();
  const { trigger: saveRetentions, load: loaderSaveRetentions } = usePostMethod();
  const myPermission = counter.loginReducer.currentAccount?.profile?.permission?.find(
    x => x.functionality?.prefix === 'plandenom',
  );
  const { trigger: changeColor } = usePostMethod();

  const isDisableCheckNews = ['approved', 'settlement'].includes(props?.details?.status || '');

  const token = storage.loginReducer.Authorization;
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [rowStates, setRowStates] = useState({});

  const [filters, setFilters] = useState({
    entity_account: storage.loginReducer.currentAccount.id,
    search: '',
    accrued: undefined,
    otherAccrued: undefined,
    otherDeduction: undefined,
    deductions: undefined,
    id: '',
    orderDays: undefined,
    days_to_pay: '',
    adjustment: '',
  });

  const [modalDetailNews, setModalDetailNews] = useState({
    show: false,
    entity_account: storage.loginReducer.currentAccount.id,
  });

  const [templateDetailNews, setTemplateDetailNews] = useState({});
  const [showModalRejected, setShowModalRejected] = useState({
    show: false,
    justify: '',
  });

  const [accruedDisabled, setAccruedDisabled] = useState(false);
  const [otherAccruedDisabled, setOtherAccruedDisabled] = useState(true);
  const [deductionsDisabled, setDeductionsDisabled] = useState(false);
  const [otherDeductionsDisabled, setOtherDeductionsDisabled] = useState(true);

  //-------------- Table 🍕   --------------
  const [headerTable, setheaderTable] = useState([]);
  const [dataTable, setdataTable] = useState([]);
  const [totals, setTotals] = useState({
    employee: '',
    title_job: '',
    account_nbr: '',
    days_to_pay: '',
    base_salary_days: 0,
    total_accrued: 0,
    total_deductions: 0,
    total_payment: 0,
    total_pay: 0
  });
  const [payroll_template_date, set_payroll_template_date] = useState('');

  const [optionsOtherDeductions, setOptionsOtherDeductions] = useState([
    { label: 'Seleccionar...', id: null },
  ]);
  const [optionsOtherAccrueds, setOptionsOtherAccrueds] = useState([
    { label: 'Seleccionar...', value: undefined },
  ]);

  const { trigger: getImbalance, results: resultImbalace, load: loadImbalance } = useGetMethod();
  const { trigger: putValidateEmployeeStatus, load: loadValidateEmployeeStatus } = usePostMethod();

  const mainContainerRef = useRef(null);

  const tableContainerRef = useRef(null);
  //--------------first functions 🍕   --------------

  useEffect(() => {
    if (props?.payroll_sheet !== '') {
      getDetailVariable(
        {
          entity_account: filters.entity_account,
          search: filters.search,
          id: props?.payroll_sheet,
          accrued: filters.accrued,
          deductions: filters.deductions,
          days_to_pay: filters.days_to_pay,
          orderDays: filters.orderDays,
          adjustment: filters.adjustment,
        },
        1,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger, filters.accrued, filters.deductions, filters.adjustment]); //

  useEffect(() => {
    if (modalDetailNews.show) {
      getTemplateDetailNews(modalDetailNews);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalDetailNews.show, triggerNews]);

  useEffect(() => {
    async function fetchAsyncData(type) {
      try {
        const response = await getSelectsData({
          url: '/payroll/adjustments/',
          objFilters: { entity_account: filters.entity_account, status: 'enabled', type: type },
          token: storage.loginReducer.Authorization,
        });

        const c = response?.results?.map(item => ({
          label: item.description,
          value: item.id,
        }));

        const mappedOptions = [{ label: 'Seleccionar...', value: null }, ...c];

        if (type === 1) {
          setOptionsOtherDeductions(mappedOptions);
        } else if (type === 2) {
          setOptionsOtherAccrueds(mappedOptions);
        }
      } catch (error) {
        console.error(error);
      }
    }

    fetchAsyncData(1);
    fetchAsyncData(2);
  }, [
    storage.loginReducer.Authorization,
    filters.entity_account,
    getSelectsData,
    setOptionsOtherDeductions,
    setOptionsOtherAccrueds,
  ]);
  //Options -_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_

  let optionsAccrued = [
    { key: 'optionsAccrued0', value: '', label: 'Seleccionar...', id: '0' },
    { key: 'optionsAccrued1', value: 'overtime', label: 'Horas extras', id: '1' },
    { key: 'optionsAccrued2', value: 'inhabilities', label: 'Incapacidades', id: '2' },
    { key: 'optionsAccrued3', value: 'vacations', label: 'Vacaciones', id: '3' },
    {
      key: 'optionsAccrued4',
      value: 'advance_vacations',
      label: 'Vacaciones anticipadas',
      id: '4',
    },
    { key: 'optionsAccrued5', value: 'transp_support', label: 'Auxilio de transporte', id: '5' },
    {
      key: 'optionsAccrued10',
      value: 'connectivity_support',
      label: 'Auxilio de conectividad',
      id: '10',
    },
    {
      key: 'optionsAccrued11',
      value: 'non_const_income',
      label: 'Ingreso no constitutivo',
      id: '11',
    },
    {
      key: 'optionsAccrued12',
      value: 'parental_leave',
      label: 'Licencia de maternidad y/o paternidad',
      id: '12',
    },
    {
      key: 'optionsAccrued13',
      value: 'bonus_payment',
      label: 'Primas',
      id: '13',
    },
    { key: 'optionsAccrued14', value: 'other_accrued', label: 'Otros devengados', id: '14' },
  ];

  let optionsDeductions = [
    { key: 'optionsDeductions0', value: '', label: 'Seleccionar...', id: '0' },
    { key: 'optionsDeductions1', value: 'healthcare_deducted', label: 'Salud', id: '1' },
    { key: 'optionsDeductions2', value: 'pension_deducted', label: 'Pensión', id: '2' },
    {
      key: 'optionsDeductions3',
      value: 'solidary_pension_deducted',
      label: 'Solidaridad pensional',
      id: '3',
    },
    { key: 'optionsDeductions4', value: 'retentions', label: 'Retenciones', id: '4' },
    { key: 'optionsDeductions5', value: 'permissions', label: 'Permisos', id: '5' },
    { key: 'optionsDeductions6', value: 'internal_services', label: 'Servicios internos', id: '6' },
    {
      key: 'optionsDeductions7',
      value: 'bonus_payment',
      label: 'Anticipo de primas',
      id: '7',
    },
    { key: 'optionsDeductions8', value: 'others_deducted', label: 'Otras deducciones', id: '8' },
  ];
  let optionsOrder = [
    { key: 'orderDays0', value: '', label: 'Seleccionar...', id: '0' },
    { key: 'orderDays1', value: 'mayorTo_minor', label: 'Mayor a menor', id: '1' },
    { key: 'orderDays2', value: 'minorTo_mayor', label: 'Menor a mayor', id: '2' },
  ];

  //Detail template actions -_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_

  const resetScroll = () => {
    if (mainContainerRef.current) {
      mainContainerRef.current.scrollTop = 0
    }
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTop = 0
    }
  }

  const validateEmployeeStatus = (id, callBack) => {
    putValidateEmployeeStatus({
      url: `/payroll/review_employee/`,
      method: 'PUT',
      body: {
        payroll_sheet_id: id,
      },
      token: storage.loginReducer.Authorization,
      succesAction: res => {
        if (res?.employees_rejected) {
          customSwaltAlert({
            icon: 'warning',
            title: 'Advertencia',
            text: res?.message,
            confirmButtonText: 'Aceptar',
            showCancelButton: true,
          })
        }
        if (res?.employees?.length) {
          customSwaltAlert({
            title: `¿Está seguro?`,
            text: res?.message,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, Continuar',
            cancelButtonText: 'Cancelar',
          }).then(response => {
            if (response.isConfirmed) {
              callBack()
            }
          })
        } else {
          callBack()
        }
      },
      doAfterException: res => {
        customSwaltAlert({
          icon: 'warning',
          title: 'Advertencia',
          text: res?.message,
          confirmButtonText: 'Aceptar',
          showCancelButton: true,
        })
      },
    });
  }

  const approvedPayroll = () => {
    customSwaltAlert({
      title: `¿Está seguro?`,
      text:
        'Se aprobará la planilla de nómina del mes de ' +
        props.month +
        ', con fecha de pago: ' +
        props.details.paymentDate,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, Continuar',
      cancelButtonText: 'Cancelar',
    }).then(response => {
      if (response.isConfirmed) {
        setIsLoading(true);
        dispatch(
          creatPayroll(
            {
              eaccount: storage.loginReducer.currentAccount.id,
              payroll: props.selectedPayroll,
              status: 'approved',
              user: storage.loginReducer.user_data.id,
              module: 'payrollApro',
              confirmation: 0
            },
            () => {
              props.setDetails({ show: false });
              dispatch(
                getListPayrollAuthorization({
                  eaccount: storage.loginReducer.currentAccount.id,
                  module: 'payrollApro',
                }),
              );
            },
            () => {
              setIsLoading(false);
            },
            `Planilla aprobada exitosamente.`,
          ),
        );
      }
    });
  };

  const preApprovedPayroll = () => {
    customSwaltAlert({
      title: `¿Está seguro?`,
      text:
        'Se enviará a revisión planilla de nómina del mes de ' +
        props.month +
        ', con fecha de pago: ' +
        props.details.paymentDate,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, Continuar',
      cancelButtonText: 'Cancelar',
    }).then(response => {
      if (response.isConfirmed) {
        validateEmployeeStatus(props.selectedPayroll, () => {
          setIsLoading(true);
          dispatch(
            creatPayroll(
              {
                eaccount: storage.loginReducer.currentAccount.id,
                payroll: props.selectedPayroll,
                status: 'pre-approved',
                user: storage.loginReducer.user_data.id,
                confirmation: 1
              },
              () => {
                props.setDetails({ show: false });
                dispatch(
                  getListPayrollAuthorization({
                    eaccount: storage.loginReducer.currentAccount.id,
                    module: 'payrollAuth',
                  }),
                );
              },
              () => {
                setIsLoading(false);
              },
              `Se envió a revisión planilla de nómina del mes de ${props.month}, con fecha de pago: ${props.details.paymentDate}`,
            ),
          );
        })

      }
    });
  };

  const rejectedPayroll = () => {
    customSwaltAlert({
      title: `¿Está seguro?`,
      text:
        'Se denegará el pago de la planilla de nómina del mes de ' +
        props.month +
        ', con fecha de pago: ' +
        props.details.paymentDate,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, Continuar',
      cancelButtonText: 'Cancelar',
    }).then(response => {
      if (response.isConfirmed) {
        setShowModalRejected({ ...showModalRejected, show: true });
      }
    });
  };

  const handleCloseImbalanceModal = () => {
    setShowImbalanceModal(false);
  };

  const sendrejectedPayroll = () => {
    setIsLoading(true);
    dispatch(
      creatPayroll(
        {
          eaccount: storage.loginReducer.currentAccount.id,
          payroll: props.selectedPayroll,
          status: 'rejected',
          user: storage.loginReducer.user_data.id,
          rejected: showModalRejected.justify,
          confirmation: 0
        },
        () => {
          props.setDetails({ show: false });
          dispatch(
            getListPayrollAuthorization({
              eaccount: storage.loginReducer.currentAccount.id,
              module: 'payrollAuth',
            }),
          );
        },
        () => {
          setIsLoading(false);
        },
        `Se denegó planilla de nómina del mes de ${props.month}, con fecha de pago: ${props.details.paymentDate}`,
      ),
    );
  };

  const getDetailVariable = async (data, type) => {
    setIsLoading(true);
    let str_filters = convertFilterToString(data);
    await fetch(`${URL_GATEWAY}${API_VERSION}/payroll/payroll-template/?${str_filters}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
      .then(response => response.json())
      .then(res => {
        setIsLoading(false);

        if (type === 1) {
          setheaderTable(res.header);
        } else if (filters) {
          const defaultOptions = [
            { path: 'gear', name: '' },
            { path: 'note', name: '' },
          ];

          setheaderTable([...res.header, ...defaultOptions]);
        }
        setdataTable(res.data);
        setTotals({
          ...totals,
          base_salary_days: res?.totals?.sum_base_salary ?? 0,
          total_accrued: res?.totals?.sum_accrued ?? 0,
          total_deductions: res?.totals?.sum_deductions ?? 0,
          total_payment: res?.totals?.sum_payment ?? 0
        })
        set_payroll_template_date(res.payroll_template_date);
        setTriggerTable(triggerTable + 1);
      })
      .catch(err => {
        setIsLoading(false);
      });
  };

  const getTemplateDetailNews = async data => {
    setIsLoading(true);
    let str_filters = convertFilterToString(data);

    await fetch(
      `${URL_GATEWAY}${API_VERSION}/payroll/payroll-template-detail-news/?${str_filters}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: token,
        },
      },
    )
      .then(response => response.json())
      .then(res => {
        if (!res.success) {
          setIsLoading(false);
          return customSwaltAlert({
            icon: 'error',
            title: 'Error',
            text: res.message,
            confirmButtonText: 'Aceptar',
            showCancelButton: false,
          });
        }
        setIsLoading(false);

        setModalDetailNews({
          ...modalDetailNews,
          base_salary_accrued: res.base_salary_accrued,
          average_salary: res.average_salary,
        });

        setTemplateDetailNews({
          ...res,
          template_detail: modalDetailNews.id,
          contract_id: modalDetailNews.contract_id,
          template_id: modalDetailNews.template_id,
          user_id: storage.loginReducer.user_data.id,
        });
      })
      .catch(err => {
        setIsLoading(false);
      });
  };

  const onSubmitValidateNews = () => {
    const paymentDate = moment(payroll_template_date);
    const currentDate = moment();

    let isValid = true;

    if (props.details.status === 'loaded_settlement') {
      isValid = !currentDate.isAfter(paymentDate.clone().add(10, 'days'), 'day');
    } else {
      isValid = !Number(currentDate.format('YYYYMM') > paymentDate.format('YYYYMM'));
    }
    if (!isValid) {
      customSwaltAlert({
        icon: 'warning',
        title: 'Advertencia',
        showCancelButton: false,
        text: `<p>No se puede ingresar una novedad fuera de las fechas establecidas para la planilla de nómina.</p>
               <p>Por favor, verifique las fechas correspondientes y actualice la información según sea necesario.</p>`,
      });
    }
    return isValid;
  };

  const executePutNewsDetail = async () => {
    if (onSubmitValidateNews()) {
      if (
        calcTotalAccrued + Number(modalDetailNews?.base_salary_accrued) - calcTotalDeductions <=
        0
      ) {
        const result = await customSwaltAlert({
          icon: 'warning',
          title: 'Advertencia',
          text: 'El valor neto a pagar es negativo o igual a cero. ¿Deseas continuar de todas formas?',
          confirmButtonText: 'Aceptar',
          showCancelButton: true,
        });

        if (!result.isConfirmed) {
          return;
        }
      }

      setModalDetailNews(state => ({ ...state, show: false }));
      setIsLoading(true);
      const data = {
        ...templateDetailNews,
      };
      await fetch(`${URL_GATEWAY}${API_VERSION}/payroll/payroll-template-detail-news/`, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: token,
        },
      })
        .then(response => response.json())
        .then(res => {
          if (res.success) {
            setIsLoading(false);
            customSwaltAlert({
              icon: 'success',
              title: 'Datos guardados exitosamente',
              text: res.message,
              confirmButtonText: 'Aceptar',
              showCancelButton: false,
            });
            setTrigger(trigger + 1);
            setTriggerNews(triggerNews + 1);
          }
          if (!res.success) {
            customSwaltAlert({
              icon: 'warning',
              title: 'Intenta de nuevo',
              text: res.message,
              confirmButtonText: 'Aceptar',
              showCancelButton: false,
            });
          }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            text: err.message,
          });
          setIsLoading(false);
        });
      setIsLoading(false);
    }
  };

  const downloadPayCheck = async index => {
    setIsLoading(true);
    const result = await getPdfTest(
      {
        id: dataTable[index]?.id,
        doc: 20,
      },
      token,
    );

    setIsLoading(false);
    if (result?.success) {
      downloadFilePdf(
        result?.pdf[0]?.key?.split("'")[1],
        `volante_de_pago_${dataTable[index]?.employee}`,
      );
    } else {
      message('error', 'Ha ocurrido un error', 'No ha sido posible cargar el documento');
    }
  };

  const downloadExcelDoc = consultType => {
    postDocExcel({
      url: '/payroll/download-payroll-template/',
      method: 'POST',
      body: {
        entity_account: storage.loginReducer.currentAccount.id,
        id: props?.payroll_sheet,
      },
      token: storage.loginReducer.Authorization,
      succesAction: results => {
        genericDownloadExcel(results?.results?.base64, results?.results?.filename);
        customSwaltAlert({
          showCancelButton: false,
          icon: 'success',
          title: 'Excel descargado',
          text: results.message,
          confirmButtonText: 'Aceptar',
        });
      },
      doAfterException: results => {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: results.message,
          confirmButtonText: 'Aceptar',
        });
      },
    });
  };
  const saveRetentionFunction = value => {
    saveRetentions({
      url: '/payroll/calculate_retention/',
      method: 'PUT',
      body: {
        template_detail: modalDetailNews.id,
        tax_basis: value,
        entity_account: storage.loginReducer.currentAccount.id,
      },
      token: storage.loginReducer.Authorization,
      succesAction: results => {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'success',
          title: 'Retención guardada exitosamente',
          text: results.message,
          confirmButtonText: 'Aceptar',
        });
      },
      doAfterException: results => {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: results.message,
          confirmButtonText: 'Aceptar',
        });
      },
    });
  };

  const handleDeductionsChange = e => {
    resetScroll();
    const value = e.value;
    setFilters({ ...filters, deductions: value, otherDeduction: '', adjustment: '', accrued: '' });
    if (value === 'others_deducted') {
      setAccruedDisabled(true);
      setOtherAccruedDisabled(true);
      setDeductionsDisabled(false);
      setOtherDeductionsDisabled(false);
    } else {
      setAccruedDisabled(false);
      setOtherAccruedDisabled(true);
      setDeductionsDisabled(false);
      setOtherDeductionsDisabled(true);
    }
  };

  const handleAccruedChange = e => {
    resetScroll();
    const value = e.value;
    setFilters({
      ...filters,
      accrued: value,
      otherDeduction: '',
      adjustment: '',
      deductions: '',
      otherAccrued: '',
    });
    if (value.trim() === 'other_accrued') {
      setAccruedDisabled(false);
      setOtherAccruedDisabled(false);
      setDeductionsDisabled(true);
      setOtherDeductionsDisabled(true);
    } else {
      setAccruedDisabled(false);
      setOtherAccruedDisabled(true);
      setDeductionsDisabled(false);
      setOtherDeductionsDisabled(true);
    }
  };

  const handleOtherDeductionsChange = e => {
    resetScroll();
    const label = e.label;
    const id = e.value;
    setFilters({ ...filters, otherDeduction: label, adjustment: id });
    const newOption = [
      { path: 'adjustment', name: e.label },
      { path: '', name: '' },
    ];
    setheaderTable([...headerTable.slice(0, -2), ...newOption]);
  };

  const fetchColorFromEndpoint = (state, id) => {
    return new Promise((resolve, reject) => {
      try {
        changeColor({
          url: '/payroll/review_payroll_template_status/',
          token: token,
          method: 'PUT',
          body: {
            review_status: state,
            id_template: id,
            payroll_template: props?.selectedPayroll,
          },
          succesAction: response => {
            customSwaltAlert({
              icon: 'success',
              title: response.message,
              confirmButtonText: 'Aceptar',
              showCancelButton: false,
            }).then(() => {
              setTriggerTable(prevTriggerTable => prevTriggerTable + 1);
              setTrigger(prevTrigger => prevTrigger + 1);
            });

            resolve();
          },
          doAfterException: error => {
            customSwaltAlert({
              icon: 'warning',
              title: error.message,
              confirmButtonText: 'Aceptar',
              showCancelButton: false,
            });
            reject(error);
          },
        });
      } catch (error) {
        reject(error);
      }
    });
  };

  const handleDoubleClick = async (index, id, status, comment) => {
    const isSelected = selectedRows?.includes(index);
    let state;
    if (!isSelected) {
      setSelectedRows([...selectedRows, index]);
    }

    if (comment) {
      state = 4;
    } else {
      state =
        status === 'pending' ? 1 : status === 'in_progress' ? 2 : status === 'reviewed' ? 3 : 4;
    }

    if (state) {
      await fetchColorFromEndpoint(state, id);
    }
    setRowStates({ ...rowStates, [index]: state });
  };

  const handleOtherAccruedChange = e => {
    resetScroll();
    let value = e.label;
    let id = e.value;
    setFilters(prevFilters => ({
      ...prevFilters,
      otherAccrued: value,
      adjustment: id,
    }));

    const newOption = [
      { path: 'adjustment', name: filters.otherAccrued },
      { path: '', name: '' },
    ];
    setheaderTable([...headerTable.slice(0, -2), ...newOption]);
  };

  const put = async (data, token) => {
    try {
      const query = await fetch(
        `${URL_GATEWAY}${API_VERSION}/payroll/changeStatus_payrollSheet/${data}/`,
        {
          method: 'PUT',
          headers: {
            Authorization: token,
          },
        },
      );
      let result = await query.json();
      if (result.success) {
        customSwaltAlert({
          icon: 'success',
          title: 'Enviada exitosamente',
          text: `Se ha enviado a contabilidad planilla del año ${props?.details?.elem?.payment_year}, periodo ${props?.details?.elem?.range}`,
          showCancelButton: false,
          confirmButtonText: 'Aceptar',
        });
        setTrigger(trigger + 1);
      }
      return result;
    } catch (error) {
      return message('error', 'Error', 'Ha ocurrido un error', undefined, true);
    }
  };

  const isThereImbalance = () => {
    return new Promise((resolve, _) => {
      getImbalance({
        url: '/payroll/verify_data',
        objFilters: {
          entity_account: storage.loginReducer.currentAccount.id,
          payroll_id: props.payroll_sheet,
        },
        token: token,
        succesAction: res => {
          if (res.success) {
            if (res.results.length !== 0) {
              setShowImbalanceModal(true);
              resolve(true);
            }
          }
          resolve(false);
        },
      });
    });
  };

  const sendToAccountingValidation = async data => {
    const imbalance = await isThereImbalance();
    if (imbalance) {
      return;
    }
    customSwaltAlert({
      icon: 'warning',
      title: '¿Está seguro?',
      showCancelButton: true,
      text: `Se enviará la plantilla de nómina año ${props?.details?.elem?.payment_year}, periodo ${props?.details?.elem?.range}`,
      confirmButtonColor: '#005DBF',
      cancelButtonColor: '#fff',
      confirmButtonText: 'Sí, continuar',
      cancelButtonText: 'Cancelar',
    }).then(response => {
      if (response.isConfirmed) {
        sendToAccounting(data);
      }
    });
  };
  const sendToAccounting = async data => {
    try {
      setIsLoading(true);
      const result = await put(data, token);

      if (result.success) {
        setIsLoading(false);
        props?.setDetails({ ...props?.details, show: false, sheet: 0 });
        props?.setShowModal({ ...props?.showModal, show: false });
        props?.setTrigger(props?.trigger + 1);
      } else {
        setIsLoading(false);
        message('error', 'Error', result.message, undefined, true);
      }
    } catch (error) {
      setIsLoading(false);

      return message(
        'error',
        'Error',
        'Ha ocurrido un error al enviar la planilla a contabilidad',
        undefined,
        true,
      );
    }
  };

  const [adjustments, setAdjustments] = useState([]);
  const [triggerAdj, setTriggerAdj] = useState(0);
  const getAdjustments = async (args, doAfter) => {
    let str_filters = convertFilterToString(args);
    const getMyStuff = await fetch(
      `${URL_GATEWAY}${API_VERSION}/payroll/adjustments/Type/?${str_filters}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: token,
        },
      },
    )
      .then(response => response.json())
      .then(res => {
        setAdjustments(res.result);
        setTriggerAdj(triggerAdj + 1);
        doAfter();
      });
  };

  const adjustmentOptions = () => {
    const sourceArray =
      modalDetailNews.editTitle === 'Agregar deducción'
        ? templateDetailNews?.deductions
        : templateDetailNews?.accrued;
    if (Array.isArray(adjustments) && Array.isArray(sourceArray)) {
      return adjustments.map(elem => {
        const existindex = sourceArray.findIndex(s => s.adjustments_id === elem.adjustments_id);
        return {
          value: elem.adjustments_id,
          label: elem.description,
          isDisabled: Array.isArray(sourceArray) ? existindex !== -1 : false,
        };
      });
    }
    return [];
  };

  const renderDedutions = (item, index, sourceArray, arrayName, arrayType) => {
    return (
      <div key={index} className='d-flex justify-content-between'>
        {item.new ? (
          <img
            src={CanecaGris}
            onClick={() => deleteNewsCheck(sourceArray, index)}
            style={{ width: 24, height: 24, margin: 9 }}
            alt='icon'
          />
        ) : (
          <Checkbox
            checked={isDisableCheckNews || item.checked || item.fixed}
            onChange={() => handleNewsCheckbox(sourceArray, index)}
            disabled={isDisableCheckNews || item.fixed}
          />
        )}
        <p
          className={`${(!item.fixed || (item?.retentions && props.details.status !== 'approved' && props.details.status !== 'settlement')) && 'pointer'} ${Styles.NewsRowText}`}
          style={{ flex: 1 }}
          onClick={() => {
            if (
              !item.fixed ||
              (item?.retentions &&
                props.details.status !== 'approved' &&
                props.details.status !== 'settlement')
            ) {
              !isDisableCheckNews &&
                openEditModal(
                  item,
                  item?.retentions ? 'Recalcular retención' : arrayName,
                  arrayType,
                );
            }
          }}
        >
          {item.checked ? item?.name_att : item?.fixed ? item?.name_att : item?.adjDescription}
        </p>
        <p
          className={`${(!item.fixed || (item?.retentions && props.details.status !== 'approved' && props.details.status !== 'settlement')) && 'pointer'} ${Styles.NewsRowText}`}
          onClick={() => {
            if (
              !item.fixed ||
              (item?.retentions &&
                props.details.status !== 'approved' &&
                props.details.status !== 'settlement')
            ) {
              !isDisableCheckNews &&
                openEditModal(
                  item,
                  item?.retentions ? 'Recalcular retención' : arrayName,
                  arrayType,
                );
            }
          }}
        >
          {formatter.format(item.value)}
        </p>
      </div>
    );
  };

  const renderListHeaderModal = () => {
    return headerTable.map(({ path, name, comment }) => {
      const baseStyle = { flex: 1.5 };
      switch (path) {
        case 'employee':
          return (
            <div key={path} className='text-start' style={{ flex: 3, paddingLeft: 14 }}>
              {name}
            </div>
          );
        case 'title_job':
          return (
            <div key={path} className='text-center' style={{ flex: 2 }}>
              {name}
            </div>
          );
        case 'days_to_pay':
          return (
            <div key={path} style={{ flex: 2 }}>
              {name}
            </div>
          );
        case 'note':
          let noteV = null;
          if (!props.fromAuth) {
            noteV = (
              <div style={{ paddingRight: 14 }}>
                <img
                  alt='note'
                  src={note}
                  height='23px'
                  className={`${tableStyles.svgFilterDarkBlue} cursorPointer`}
                  onClick={() => {
                    setCommentsModal({ ...commentsModal, show: true, detail: false });
                  }}
                />
              </div>
            );
          }
          return noteV;
        case 'account_nbr':
          if (!props.fromAuth) {
            return (
              <div key={path} style={baseStyle}>
                {name}
              </div>
            );
          }
          return null;
        case '':
          if (!props.fromAuth) {
            return (
              <div key={path} style={{ flex: 1.05 }}>
                {name}
              </div>
            );
          }
          return <></>;
        default:
          if (path) {
            return (
              <div key={path} style={baseStyle}>
                {name}
              </div>
            );
          }
          return <></>;
      }
    });
  };

  const renderListFooterTotal = objectValues => {
    let header = [];
    const renderCell = (value, index, path, flex = 1.5,) => {
      const isNumber = !isNaN(value) && typeof value === 'number';
      const isString = typeof value === 'string';
      return (
        <div
          className={`text-center ${Styles.totalRowText}`}
          key={index + path}
          style={{ flex }}
        >
          {isNumber ? formatter.format(value) : isString ? value : 0}
        </div>
      );
    };
    if (Array.isArray(headerTable) && objectValues != null) {
      header = headerTable.map((item, index) => {
        switch (item.path) {
          case 'gear':
            let y = null;
            if (!props.fromAuth) {
              y = (
                <div className={`${Styles.totalRowText}`} style={{ flex: 1 }} key={index + item.path}>
                  &nbsp;
                </div>
              );
            }
            return y;
          // return
          case 'note':
            // return
            let noteV = null;
            if (!props.fromAuth) {
              // ANCHOR 2
              noteV = (
                <div
                  className={`${Styles.totalRowText}`}
                  style={{
                    paddingRight: 14,
                    height: '36.98px',
                    width: `${dataTable.length < 10 ? '35.76px' : '40.76px'} `,
                  }}
                  key={index + item.path}
                >
                  &nbsp;
                </div>
              );
            }
            return noteV;
          case 'employee':
            return (
              <div className={`text-start`} style={{ flex: 3, paddingLeft: 14 }} key={index + item.path}>
                &nbsp;
              </div>
            );

          case 'title_job':
            return (
              <div className={`text-center`} style={{ flex: 2 }} key={index + item.path}>
                &nbsp;
              </div>
            );

          case 'days_to_pay':
            return (
              <div className={`text-center ${Styles.totalRowText}`} style={{ flex: 2, fontSize: 14 }} key={index}>
                TOTAL
              </div>
            );
          case '':
            if (!props.fromAuth) {
              return (
                <div
                  className={`text-center ${Styles.totalRowText}`}
                  key={index + item.path}
                  style={{ flex: 1.05 }}
                ></div>
              );
            } else {
              return <></>;
            }
          case 'account_nbr':
            if (!props.fromAuth) {
              return (
                <div
                  className={`text-center ${Styles.totalRowText}`}
                  key={index + item.path}
                  style={{ flex: 1.5 }}
                ></div>
              );
            } else {
              return <></>;
            }
          default:
            if (item.path) {
              return (
                renderCell(objectValues[item.path], index, item.path)
              );
            }
            return <></>;
        }
      });
    }
    return header;
  };
  const renderTooltipDetail = () => <Tooltip>Ver detalle</Tooltip>;

  const renderTooltipRetentions = props => (
    <Tooltip {...props}>
      <div>
        <span>Recalcular retenciones</span>
      </div>
    </Tooltip>
  );

  const [showEmployeeDetails, setShowEmployeeDetails] = useState({
    show: false,
    data: '',
  });
  const [renderListModal, footervalues] = useMemo(() => {
    let body = [];
    let footervalues = headerTable.reduce((acc, { path }) => {
      acc[path] = 0;
      return acc;
    }, {});

    const renderCell = (path, rowData, flex = 1.5) => {
      const value = rowData[path];
      const isNumber = !isNaN(value) && typeof value === 'number';
      if (isNumber) {
        footervalues[path] = footervalues[path] + value;
      }
      return (
        <div style={{ flex, backgroundColor: rowData.comments && '#FCF1E1' }}>
          {isNumber ? convertMoneyFormat(value || 0) : value || ''}
        </div>
      );
    };

    body = dataTable?.map((rowData, index) => (
      <section
        className='d-flex'
        key={index}
        style={{
          backgroundColor: props.fromAuth
            ? rowData.comments
              ? '#FCF1E1'
              : rowData.background_color
            : rowData.comments && '#FCF1E1',
        }}
        onDoubleClick={() =>
          props.fromAuth &&
          handleDoubleClick(index, rowData?.id, rowData?.review_status, rowData?.comments)
        }
      >
        {headerTable?.map(({ path }) => {
          switch (path) {
            case 'gear':
            case '':
              let y = null;
              if (!props?.fromAuth) {
                y = (
                  <div style={{ flex: 1.05, backgroundColor: rowData.comments && '#FCF1E1' }}>
                    <CustomPopupExtend
                      showDelete={props?.details.status === 'loaded' ? true : false}
                      showOtroSi={
                        props?.details.status === 'loaded' || props?.details.status === 'approved'
                          ? true
                          : false
                      }
                      otroSi={'Volante de pago'}
                      noHover
                      deleteText={'No incluir'}
                      deleteEvent={() => null}
                      otroSiEvent={() => downloadPayCheck(index)}
                      triggerSrc={threeDots}
                      showEdit={!!myPermission.edit && true}
                      editText='Novedades'
                      editClickEvent={() => {
                        setModalDetailNews({
                          ...modalDetailNews,
                          ...rowData,
                          show: true,
                          id: rowData.id,
                          template_id: rowData.template_id,
                          total_payment: rowData.total_payment,
                          total_deductions: rowData.total_deductions,
                          total_accrued: rowData.total_accrued,
                        });
                      }}
                      extraButtons={
                        rowData?.comments == null
                          ? []
                          : [
                            {
                              text: 'Comentario',
                              icon: note,
                              class: '',
                              event: () =>
                                setCommentsModal({
                                  ...commentsModal,
                                  show: true,
                                  detail: false,
                                  comment: rowData.comments,
                                }),
                            },
                          ]
                      }
                    />
                  </div>
                );
              }
              return y;

            case 'note':
              let noteV = null;
              if (!props.fromAuth || rowData?.comments !== null) {
                noteV = rowData.comments ? (
                  <div
                    style={{
                      paddingRight: 14,
                      height: '36.98px',
                      width: '35.76px',
                      backgroundColor: rowData.comments && '#FCF1E1',
                    }}
                  >
                    <img
                      alt='note'
                      src={note}
                      height='23px'
                      width='23px'
                      className={`${tableStyles.svgFilterDarkBlue} cursorPointer`}
                      onClick={() => {
                        setCommentsModal({
                          ...commentsModal,
                          show: true,
                          detail: false,
                          comment: rowData.comments,
                        });
                      }}
                    />
                  </div>
                ) : (
                  //ANCHOR
                  <div style={{ height: '36.98px', width: '35.76px', paddingRight: 14 }}>
                    &nbsp;
                  </div>
                );
              }
              return noteV;

            case 'employee':
              footervalues[path] = '';
              let employeeV = null;
              if (props.fromAuth) {
                employeeV = (
                  <OverlayTrigger
                    placement='top'
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltipDetail()}
                  >
                    <div
                      className={`text-start ${tableStyles.textDetailNom}`}
                      style={{
                        flex: 3,
                        paddingLeft: 14,
                        backgroundColor: rowData.comments && '#FCF1E1',
                      }}
                      onClick={() => onOpenDetailEmployee(rowData)}
                    >
                      {rowData[path]}
                    </div>
                  </OverlayTrigger>
                );
              } else {
                employeeV = (
                  <div
                    className={`text-start`}
                    style={{
                      flex: 3,
                      paddingLeft: 14,
                      backgroundColor: rowData.comments && '#FCF1E1',
                    }}
                  >
                    {rowData[path]}
                  </div>
                );
              }
              return employeeV;

            case 'title_job':
              return (
                <div
                  key={path}
                  style={{
                    flex: 2,
                    textWrap: 'balance',
                    backgroundColor: rowData.comments && '#FCF1E1',
                  }}
                >
                  {rowData[path]}
                </div>
              );

            case 'days_to_pay':
              footervalues[path] = 'TOTAL';
              return (
                <div key={path} style={{ flex: 2, backgroundColor: rowData.comments && '#FCF1E1' }}>
                  {rowData[path]}
                </div>
              );
            case 'account_nbr':
              footervalues[path] = footervalues[path] + rowData[path];
              if (!props.fromAuth) {
                return (
                  <div
                    key={path}
                    style={{ flex: 1.5, backgroundColor: rowData.comments && '#FCF1E1' }}
                  >
                    {rowData[path]}
                  </div>
                );
              } else {
                return <></>;
              }
            default:
              if (path) {
                return renderCell(path, rowData);
              }
              return <></>;
          }
        })}
      </section>
    ));

    return [body, footervalues];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerTable, trigger]);

  //Back to page  -_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_
  const handlerClick = () => {
    //history?.push("/nomina/planillaDeNomina");
    props?.setDetails({ ...props?.details, show: false, sheet: 0 });
    props?.setShowModal({ ...props?.showModal, show: false });
    props?.setTrigger(props.trigger + 1);
  };

  const handleNewsCheckbox = (sourceArray, index) => {
    let objectChosen = sourceArray[index];
    if (objectChosen?.checked) {
      objectChosen.checked = false;
    } else {
      objectChosen.checked = true;
    }
  };

  const deleteNewsCheck = (sourceArray, index) => {
    sourceArray.splice(index, 1);
  };

  const addNovedadToList = () => {
    let validations =
      modalDetailNews.editTitle === 'Recalcular retención'
        ? validateEmptyString([
          { field: modalDetailNews.tax_basis, message: 'La base gravable es obligatoria' },
        ])
        : validateEmptyString([
          { field: modalDetailNews.editNovedadID, message: 'El tipo de novedad obligatoria' },
          { field: modalDetailNews.editQTY, message: 'La cantidad es obligatoria' },
        ]);

    if (validations.valid) {
      let tempArray = null;
      if (
        modalDetailNews.editTitle === 'Agregar deducción' ||
        modalDetailNews.editTitle === 'Recalcular retención'
      ) {
        tempArray = templateDetailNews?.deductions;
      }
      if (modalDetailNews.editTitle === 'Agregar devengado') {
        tempArray = templateDetailNews?.accrued;
      }
      if (Array.isArray(tempArray)) {
        let foundindex = tempArray.findIndex(
          existing => existing.name_att === modalDetailNews?.editNovedad,
        );
        let findRow = tempArray.find(
          existing => existing.name_att === modalDetailNews?.editNovedad,
        );
        if (findRow?.retentions) {
          tempArray[foundindex] = {
            ...tempArray[foundindex],
            tax_basis: modalDetailNews?.tax_basis,
          };
          saveRetentionFunction(modalDetailNews.tax_basis);
        } else if (foundindex >= 0) {
          tempArray[foundindex] = {
            name_att: modalDetailNews?.editNovedad,
            value: modalDetailNews?.editQTY,
            fixed: false,
            checked: true,
            editExisting: modalDetailNews?.editExisting,
            new: modalDetailNews?.new,
            adjustments_id: modalDetailNews?.editNovedadID,
            adjustment_suscription: modalDetailNews?.editAdjustment_suscription,
            rel_id: modalDetailNews?.edit_rel_id,
          };
        } else {
          tempArray.push({
            name_att: modalDetailNews?.editNovedad,
            value: modalDetailNews?.editQTY,
            fixed: false,
            checked: true,
            new: true,
            adjustments_id: modalDetailNews?.editNovedadID,
            adjustment_suscription: modalDetailNews?.editAdjustment_suscription,
            rel_id: modalDetailNews?.edit_rel_id,
          });
        }

        if (
          modalDetailNews.editTitle === 'Agregar deducción' ||
          modalDetailNews.editTitle === 'Recalcular retención'
        ) {
          setTemplateDetailNews({
            ...templateDetailNews,
            deductions: tempArray,
          });
        }
        if (modalDetailNews.editTitle === 'Agregar devengado') {
          setTemplateDetailNews({
            ...templateDetailNews,
            accrued: tempArray,
          });
        }
        setModalDetailNews({
          ...modalDetailNews,
          editShow: false,
          editNovedad: null,
          editQTY: null,
          editTitle: null,
          editNovedadID: null,
          new: false,
          editAdjustment: null,
          editAdjustment_suscription: null,
          edit_rel_id: null,
          tax_basis: null,
        });
      }
    }
  };

  const calcTotalDeductions = Array.isArray(templateDetailNews?.deductions)
    ? templateDetailNews.deductions
      .map(obj => obj.value)
      .reduce((prev, current) => parseInt(prev) + parseInt(current), 0)
    : 0;

  const calcTotalAccrued = Array.isArray(templateDetailNews?.accrued)
    ? templateDetailNews.accrued
      .map(obj => obj.value)
      .reduce((prev, current) => parseInt(prev) + parseInt(current), 0)
    : 0;

  // ANCHOR
  const openEditModal = (obj, arrayName, arrayType) => {
    getAdjustments(
      {
        status: 'enabled',
        type: arrayType,
        entity_account: storage.loginReducer.currentAccount.id,
      },
      () => {
        setModalDetailNews({
          ...modalDetailNews,
          editShow: true,
          editTitle: arrayName,
          editNovedad: obj.name_att,
          editQTY: obj.value,
          editExisting: obj.new ? false : true,
          new: obj.new,
          editAdjustment: obj.adjustments_id,
          editNovedadID: obj.adjustments_id,
          editAdjustment_suscription: obj.adjustment_suscription,
          edit_rel_id: obj.rel_id,
          tax_basis: obj.tax_basis,
        });
      },
    );
  };

  const renderDeductionAccruedList = (sourceArray, arrayName, arrayType) => {
    if (Array.isArray(sourceArray)) {
      return (
        <>
          {arrayName === 'devengado' && (
            <div key={'aa'} className='d-flex justify-content-between'>
              <Checkbox checked={true} disabled={true} />
              <p className={Styles.NewsRowText} style={{ flex: 1 }}>
                Salario base
              </p>
              <p className={Styles.NewsRowText}>
                {formatter.format(modalDetailNews?.base_salary_accrued)}
              </p>
            </div>
          )}

          {sourceArray.map((item, index) => {
            if (
              item.retentions &&
              props.details.status !== 'approved' &&
              props.details.status !== 'settlement'
            ) {
              return (
                <OverlayTrigger
                  key={index}
                  placement='top'
                  delay={{ show: 50, hide: 100 }}
                  overlay={renderTooltipRetentions}
                >
                  {renderDedutions(item, index, sourceArray, arrayName, arrayType)}
                </OverlayTrigger>
              );
            }
            return renderDedutions(item, index, sourceArray, arrayName, arrayType);
          })}
        </>
      );
    } else {
      return [];
    }
  };

  const renderNewsModalBody = (
    <div>
      <div className='d-flex flex-column'>
        <label className={`${tableStyles.crudModalLabel}`}>Empleado</label>
        <input
          className={IndividualStyles.registerInputsBlue}
          type='text'
          value={modalDetailNews?.employee}
          disabled={true}
        ></input>
      </div>
      <div className='d-flex flex-column'>
        <label className={`${tableStyles.crudModalLabel}`}>Salario contractual</label>
        <input
          className={IndividualStyles.registerInputsBlue}
          type='text'
          value={formatter.format(modalDetailNews?.base_salary)}
          disabled={true}
        ></input>
      </div>

      <div className='row'>
        <div className='d-flex flex-column col-8'>
          <label className={`${tableStyles.crudModalLabel}`}>Salario base</label>
          <input
            className={IndividualStyles.registerInputsBlue}
            type='text'
            value={formatter.format(modalDetailNews?.base_salary_accrued)}
            disabled={true}
          ></input>
        </div>

        <div className='d-flex flex-column col-4'>
          <label className={`${tableStyles.crudModalLabel}`}>Días trabajados</label>
          <input
            className={IndividualStyles.registerInputsBlue}
            type='text'
            value={modalDetailNews?.days_to_pay}
            disabled={true}
          ></input>
        </div>
      </div>

      <div className='d-flex flex-column'>
        <label className={`${tableStyles.crudModalLabel}`}>Salario promedio</label>
        <input
          className={IndividualStyles.registerInputsBlue}
          type='text'
          value={formatter.format(modalDetailNews?.average_salary ?? 0)}
          disabled={true}
        ></input>
      </div>

      <div
        className='d-flex justify-content-between'
        style={{ marginTop: '16px', marginBottom: 0 }}
      >
        <p className={Styles.textSubtitle}>Devengados</p>
        <p className={Styles.textSubtitle}>
          {formatter.format(calcTotalAccrued + Number(modalDetailNews?.base_salary_accrued))}
        </p>
      </div>

      <div style={{ height: 200, overflowY: 'auto', marginTop: 4, marginBottom: 4 }}>
        {renderDeductionAccruedList(templateDetailNews?.accrued, 'Agregar devengado', 2)}
      </div>

      {(props.details.status === 'loaded' ||
        props.details.status === 'loaded_settlement' ||
        props.details.status === 'rejected') && (
          <div className='d-flex flex-row-reverse'>
            <img
              className={Styles.iconPlus}
              src={addToList}
              alt='agregar'
              onClick={() => {
                getAdjustments(
                  {
                    type: 2,
                    entity_account: storage.loginReducer.currentAccount.id,
                    status: 'enabled',
                  },
                  () => {
                    setModalDetailNews({
                      ...modalDetailNews,
                      editShow: true,
                      editTitle: 'Agregar devengado',
                      new: true,
                    });
                  },
                );
              }}
            />
          </div>
        )}
    </div>
  );

  const [rotate, setRotate] = useState(false);

  let buttonsSlideDown = [
    {
      button: <img alt='btn' src={OrdLupa}></img>,
    },
    {
      button: (
        <img
          alt='btn'
          style={{
            transform: !rotate && 'rotate(180deg)',
          }}
          width={10}
          src={downGrey}
        ></img>
      ),
    },
  ];

  const [commentsModal, setCommentsModal] = useState({
    show: false,
    comment: '',
    detail: false,
  });

  const closeCommentModal = () => {
    setCommentsModal({ show: false });
  };

  const sendComment = () => {
    message('info', 'envío el comentario', undefined, true);
    setShowEmployeeDetails({ ...showEmployeeDetails, show: true });
    fetchColorFromEndpoint(4, commentsModal?.detail?.id);
  };

  const [detailShowEmployee, setDetailShowEmployee] = useState({
    show: false,
    showEmployeeDetails: {},
    data: {},
  });

  const onOpenDetailEmployee = (rowData) => {
    if (mainContainerRef.current) {
      scrollPositions.main = mainContainerRef.current.scrollTop;
    }
    if (tableContainerRef.current) {
      scrollPositions.table = tableContainerRef.current.scrollTop;
    }
    setDetailShowEmployee({
      ...detailShowEmployee,
      show: true,
      propsData: props.status,
      data: rowData,
    });
  }

  useEffect(() => {
    if (!detailShowEmployee.show) {
      if (mainContainerRef.current) {
        mainContainerRef.current.scrollTop = scrollPositions.main;
      }
      if (tableContainerRef.current) {
        tableContainerRef.current.scrollTop = scrollPositions.table;
      }
    }
  }, [detailShowEmployee.show]);

  //-------------- select options 🍕   --------------
  const myRender = (
    <div style={{ display: 'flex', flex: '1', overflow: 'auto' }} ref={mainContainerRef}>
      {(isLoading || loadImbalance || loadValidateEmployeeStatus) && loader}

      <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: '3rem' }}>
        {/* MODAL JUSTIFICATION */}
        <ModalNew
          title='Justificación'
          show={showModalRejected.show}
          btnYesName={showModalRejected.justify !== '' && 'Guardar'}
          btnNoName={'Cancelar'}
          size='400'
          btnYesEvent={showModalRejected.justify !== '' ? () => sendrejectedPayroll() : null}
          btnYesDisabled={false}
          onHide={() => setShowModalRejected({ ...showModalRejected, show: false })}
          btnNoEvent={() => setShowModalRejected({ ...showModalRejected, show: false })}
          btnNoDisabled={false}
        >
          <p className={tableStyles.crudModalLabel}>Justificación</p>
          <textarea
            placeholder={'Escribir...'}
            id='txtArea'
            rows='10'
            cols='10'
            onChange={e => setShowModalRejected({ ...showModalRejected, justify: e.target.value })}
            value={showModalRejected.justify}
            style={{ height: '4rem' }}
            className={`register-inputs text-secondary`}
          ></textarea>
        </ModalNew>
        {/* ANCHOR MODAL COMMENTS/NOTES */}
        <ModalNew
          title='Comentarios'
          show={commentsModal.show}
          btnNoName={'Cerrar'}
          size='400'
          btnYesEvent={commentsModal.detail ? () => sendComment() : null}
          btnYesDisabled={commentsModal.detail ? false : true}
          onHide={() => closeCommentModal()}
          btnNoEvent={() => closeCommentModal()}
          btnNoDisabled={false}
        >
          <p className={tableStyles.crudModalLabel}>Comentarios de empleado</p>
          <textarea
            placeholder={'Escribir...'}
            id='txtArea'
            rows='10'
            cols='10'
            disabled={commentsModal.detail ? false : true}
            onChange={e => setCommentsModal({ ...commentsModal, comment: e.target.value })}
            defaultValue={commentsModal.comment}
            style={{
              height: '4rem',
              borderRadius: '5px',
              border: '1.5px solid #01A0F6',
              padding: '0 0.05rem',
              width: '100%',
            }}
            className={` text-secondary mt-1 ${tableStyles.outlineNone}`}
          ></textarea>
        </ModalNew>
        {(isLoading ||
          (loadImbalance || loaderGetSelectsData || loaderPostDocExcel || loaderSaveRetentions) && (
            <div className='loading'>
              <Loader type='Oval' color='#003f80' height={100} width={100} />
            </div>
          ))}
        {!props.fromAuth ? (
          <div className='d-flex' style={{ marginLeft: '-30px' }}>
            <h1 className={tableStyles.title}>
              <img
                src={BackArrow}
                height='30px'
                alt='icon'
                onClick={() => handlerClick()}
                className={`mr-2 mb-1 cursorPointer`}
              />
              <span className={`${tableStyles.titleAdministrative}`}>
                Detalle planilla de nómina
              </span>
            </h1>
          </div>
        ) : (
          <div className='d-flex'>
            <div className={tableStyles.title}>
              <Title
                title='Detalle de planilla de nómina'
                onClickIcon={() => {
                  props.setDetails({ show: false });
                }}
              />
            </div>
          </div>
        )}
        {props.status === 'En revisión' ? (
          <label
            style={{
              color: '#FF8B00',
              fontWeight: 'bold',
              fontSize: '18px',
            }}
          >
            Revisión pendiente
            <img style={{ width: '20px', marginLeft: '5px' }} src={Alert} alt='icon' />
          </label>
        ) : props.status === 'Rechazada' ? (
          <label
            style={{
              color: 'rgba(236, 28, 36, 1)',
              fontWeight: 'bold',
              fontSize: '18px',
            }}
          >
            Planilla rechazada
            <img
              style={{ width: '20px', height: '55%', marginLeft: '5px' }}
              src={rejectedIcon}
              alt='icon'
            />
          </label>
        ) : props.status === 'Pre-aprobada' ? (
          <label
            style={{
              color: '#A0A0A0',
              fontWeight: 'bold',
              fontSize: '18px',
            }}
          >
            Planilla autorizada
            <img
              alt="icon"
              style={{
                width: '20px',
                marginLeft: '5px',
                filter: 'grayscale(100%) brightness(110%)'
              }}
              src={CheckNew}
            />
          </label>
        ) : props.status === 'Aprobada' ? (
          <label
            style={{
              color: '#00A551',
              fontWeight: 'bold',
              fontSize: '18px',
            }}
          >
            Planilla aprobada
            <img alt='icon' style={{ width: '20px', marginLeft: '5px' }} src={CheckNew} />
          </label>
        ) : null}

        <OrdSlideDownButton
          onToggleClick={() => setRotate(!rotate)}
          buttons={buttonsSlideDown}
          accordionClassName={tableStyles.OrdSlideButtonNom}
        >
          <Row>
            <Col xs={3}>
              <div className='display-grid mt-1'>
                <label className={`${tableStyles.crudModalLabel}`}>Devengados</label>
                <SelectComponent
                  placeholder='Seleccionar...'
                  styles={customSelectNewDark}
                  options={optionsAccrued}
                  onChange={handleAccruedChange}
                  isDisabled={accruedDisabled}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      resetScroll();
                      setTrigger(trigger + 1);
                    }
                  }}
                  value={optionsAccrued.find(x => x.value === filters.accrued)}
                />
              </div>
            </Col>
            <Col xs={3}>
              <div className='display-grid mt-1'>
                <label className={`${tableStyles.crudModalLabel}`}>Otros devengados</label>
                <SelectComponent
                  placeholder='Seleccionar...'
                  styles={customSelectNewDark}
                  options={optionsOtherAccrueds}
                  onChange={handleOtherAccruedChange}
                  isDisabled={otherAccruedDisabled}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      resetScroll();
                      setTrigger(trigger + 1);
                    }
                  }}
                  value={optionsOtherAccrueds.find(x => x.value === filters.adjustment)}
                />
              </div>
            </Col>
            <Col xs={3}>
              <div className='display-grid mt-1'>
                <label className={`${tableStyles.crudModalLabel}`}>Deducciones</label>
                <SelectComponent
                  placeholder='Seleccionar...'
                  styles={customSelectNewDark}
                  options={optionsDeductions}
                  onChange={handleDeductionsChange}
                  isDisabled={deductionsDisabled}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      resetScroll();
                      setTrigger(trigger + 1);
                    }
                  }}
                  value={optionsDeductions.find(x => x.value === filters.deductions)}
                />
              </div>
            </Col>
            <Col xs={3}>
              <div className='display-grid mt-1'>
                <label className={`${tableStyles.crudModalLabel}`}>Otras deducciones</label>
                <SelectComponent
                  placeholder='Seleccionar...'
                  styles={customSelectNewDark}
                  options={optionsOtherDeductions}
                  onChange={handleOtherDeductionsChange}
                  isDisabled={otherDeductionsDisabled}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      resetScroll();
                      setTrigger(trigger + 1);
                    }
                  }}
                  value={
                    otherDeductionsDisabled === false
                      ? optionsOtherDeductions.find(x => x.label === filters.otherDeduction)
                      : optionsOtherDeductions[0]
                  }
                />
              </div>
            </Col>
            <Col xs={3}>
              <div className='display-grid mt-1'>
                <label className={`${tableStyles.crudModalLabel}`}>Fecha de pago</label>
                <input
                  className={IndividualStyles.registerInputsBlue}
                  type='date'
                  value={moment(
                    convertDateToLatinFormat(payroll_template_date),
                    'DD-MM-YYYY',
                  ).format('YYYY-MM-DD')}
                  disabled={true}
                  style={{ backgroundColor: '#FFF' }}
                ></input>
              </div>
            </Col>
            <Col xs={3}>
              <div className='display-grid mt-1'>
                <label className={`${tableStyles.crudModalLabel}`}>Días menores a</label>
                <input
                  onChange={e => setFilters({ ...filters, days_to_pay: e.target.value })}
                  type='number'
                  value={filters.days_to_pay}
                  placeholder='Escribir...'
                  className={`register-inputs`}
                  name='position'
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      resetScroll();
                      setTrigger(trigger + 1);
                    }
                  }}
                />
              </div>
            </Col>
            <Col xs={3} className='align-self-end'>
              <div className='display-grid'>
                <label className={`${tableStyles.crudModalLabel}`}>Orden de días</label>
                <SelectComponent
                  placeholder='Seleccionar...'
                  styles={customSelectNewDark}
                  options={optionsOrder}
                  onChange={e => {
                    setFilters({ ...filters, orderDays: e.value });
                    resetScroll();
                    setTrigger(trigger + 1);
                  }}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      resetScroll();
                      setTrigger(trigger + 1);
                    }
                  }}
                  value={optionsOrder.find(x => x.value === filters.orderDays)}
                />
              </div>
            </Col>

            <Col xs={2} className='align-self-end'>
              <label className={`${tableStyles.crudModalLabel}`}></label>
              <input
                className={`${tableStyles.SearchNew}`}
                placeholder='Buscar...'
                type='text'
                value={filters.search}
                onChange={e =>
                  setFilters({
                    ...filters,
                    search: e.target.value,
                  })
                }
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    resetScroll();
                    setTrigger(trigger + 1);
                  }
                }}
              ></input>
            </Col>
            <Col xs={1}>
              <div className='display-grid mt-1'>
                <label className={tableStyles.crudModalLabel}>ㅤ</label>
                <i>
                  <img
                    src={LupaBlue}
                    style={{ cursor: 'pointer' }}
                    onClick={() => setTrigger(trigger + 1)}
                    alt='search'
                    height='24px'
                  />
                </i>
              </div>
            </Col>
          </Row>
        </OrdSlideDownButton>
        <button
          className='me-2'
          style={{ marginLeft: 'auto', width: 'fit-content' }}
          onClick={() => downloadExcelDoc()}
        >
          <img src={Excel} alt='' />
        </button>

        <div style={{ flex: 1 }}>
          <GenericTableScroll
            headers={renderListHeaderModal()}
            dark={true}
            body={renderListModal}
            footer2={renderListFooterTotal(footervalues)}
            typeHead={'2'}
            maxHeight={500}
            refBody={tableContainerRef}
          ></GenericTableScroll>
        </div>
        {!props.fromAuth &&
          (props?.details?.status === 'loaded' || props?.details?.status === 'rejected') ? (
          <div className={`${IndividualStyles.bottomRow}`}>
            <Button
              className={IndividualStyles.btnPrimaryDark}
              onClick={() => sendToAccountingValidation(props?.payroll_sheet)}
              disabled={false}
            >
              Enviar a contabilidad
            </Button>
            <Button
              className={IndividualStyles.btnPrimary}
              onClick={() => handlerClick()}
              disabled={false}
            >
              Cancelar
            </Button>
          </div>
        ) : null}
        {props.status === 'En revisión' && !!props.fromAuth ? (
          <div className={IndividualStyles.bottomRow} style={{ paddingRight: '0px' }}>
            <Button
              className={IndividualStyles.btnExtra}
              onClick={() => preApprovedPayroll()}
              disabled={false}
            >
              <img src={Check2} alt='check' /> <span></span> Autorizar
            </Button>

            <Button
              className={IndividualStyles.btnExtra}
              onClick={() => rejectedPayroll()}
              disabled={false}
            >
              <img src={Reject} alt='myimage' /> <span></span>Denegar
            </Button>

            <Button
              className={IndividualStyles.btnPrimary}
              onClick={() => {
                props.setDetails({ show: false });
                dispatch(
                  getListPayrollAuthorization({
                    eaccount: storage.loginReducer.currentAccount.id,
                    module: 'payrollAuth',
                  }),
                );
              }}
              disabled={false}
            >
              Cancelar
            </Button>
          </div>
        ) : props.status === 'Pre-aprobada' && props?.module?.prefix === 'payrollApro' && !!props.fromAuth ? (
          <div className={IndividualStyles.bottomRow} style={{ paddingRight: '0px' }}>
            <Button
              className={IndividualStyles.btnExtra}
              onClick={() => approvedPayroll()}
              disabled={false}
            >
              <img src={Check2} alt='check' /> <span></span> Aprobar
            </Button>

            <Button
              className={IndividualStyles.btnExtra}
              onClick={() => rejectedPayroll()}
              disabled={false}
            >
              <img src={Reject} alt='myimage' /> <span></span>Denegar
            </Button>

            <Button
              className={IndividualStyles.btnPrimary}
              onClick={() => {
                props.setDetails({ show: false });
                dispatch(
                  getListPayrollAuthorization({
                    eaccount: storage.loginReducer.currentAccount.id,
                    module: 'payrollApro',
                  }),
                );
              }}
              disabled={false}
            >
              Cancelar
            </Button>
          </div>
        ) : (props.status === 'Aprobada' || props.status === 'Rechazada' || props.status === 'Pre-aprobada') && !!props.fromAuth ? (
          <div className={IndividualStyles.bottomRow} style={{ paddingRight: '0px' }}>
            <Button
              className={IndividualStyles.btnPrimary}
              disabled={false}
              onClick={() => {
                props.setDetails({ show: false });
                dispatch(
                  getListPayrollAuthorization({
                    eaccount: storage.loginReducer.currentAccount.id,
                    module: props?.module?.prefix ?? 'payrollAuth',
                  }),
                );
              }}
            >
              Cancelar
            </Button>
          </div>
        ) : null}

        <ImbalanceWarningModal
          data={resultImbalace.results}
          show={showImbalanceModal}
          onCloseModal={handleCloseImbalanceModal}
        />

        <ModalNew
          dontClose
          title={'Novedades'}
          subtitle={modalDetailNews.subtitle}
          show={modalDetailNews.show}
          onHide={() => {
            setModalDetailNews({
              ...modalDetailNews,
              show: false,
            });
          }}
          btnYesEvent={() => executePutNewsDetail()}
          btnYesName={'Guardar'}
          btnYesDisabled={
            props.details.status === 'loaded' ||
              props.details.status === 'loaded_settlement' ||
              props.details.status === 'rejected' ||
              props.details.status === 'settlement'
              ? false
              : true
          }
          btnNoEvent={() => {
            setModalDetailNews({
              ...modalDetailNews,
              show: false,
            });
          }}
          btnNoName={'Cancelar'}
          size={'500'}
          additionalContent={renderNewsModalBody}
        >
          <div className='d-flex justify-content-between' style={{ marginTop: 0, marginBottom: 0 }}>
            <p className={Styles.textSubtitle}>Deducciones</p>
            <p className={Styles.textSubtitle}>{formatter.format(calcTotalDeductions)}</p>
          </div>

          <div style={{ height: 230, overflowY: 'auto', marginTop: 4, marginBottom: 4 }}>
            {renderDeductionAccruedList(templateDetailNews?.deductions, 'Agregar deducción', 1)}
          </div>

          {(props.details.status === 'loaded' ||
            props.details.status === 'loaded_settlement' ||
            props.details.status === 'rejected') && (
              <div className='d-flex flex-row-reverse'>
                <img
                  className={Styles.iconPlus}
                  src={addToList}
                  alt='agregar'
                  onClick={() => {
                    getAdjustments(
                      {
                        type: 1,
                        entity_account: storage.loginReducer.currentAccount.id,
                        status: 'enabled',
                      },
                      () => {
                        setModalDetailNews({
                          ...modalDetailNews,
                          editShow: true,
                          editTitle: 'Agregar deducción',
                          new: true,
                        });
                      },
                    );
                  }}
                />
              </div>
            )}

          <div className='d-flex flex-column'>
            <label className={`${tableStyles.crudModalLabel}`}>Valor final a pagar</label>
            <input
              className={IndividualStyles.registerInputsBlue}
              type='text'
              value={formatter.format(modalDetailNews?.total_payment)}
              disabled={true}
            ></input>
          </div>
        </ModalNew>

        <ModalNew
          title={`${modalDetailNews.editTitle}`}
          show={modalDetailNews.editShow}
          onHide={() => {
            setModalDetailNews({
              ...modalDetailNews,
              editShow: false,
              editNovedad: null,
              editQTY: null,
              editTitle: null,
              editNovedadID: null,
              new: false,
              editAdjustment: null,
              editAdjustment_suscription: null,
              edit_rel_id: null,
              tax_basis: null,
            });
          }}
          btnYesEvent={() => addNovedadToList()}
          btnYesName={'Guardar'}
          btnNoEvent={() => {
            setModalDetailNews({
              ...modalDetailNews,
              editShow: false,
              editNovedad: null,
              editQTY: null,
              editTitle: null,
              editNovedadID: null,
              new: false,
              editAdjustment: null,
              editAdjustment_suscription: null,
              edit_rel_id: null,
              tax_basis: null,
            });
          }}
          btnNoName={'Cancelar'}
          size={'400'}
        >
          {modalDetailNews.editTitle !== 'Recalcular retención' ? (
            <div className='mb-2'>
              <div className='d-flex flex-column'>
                <label className={`${tableStyles.crudModalLabel}`}>Tipo de novedad</label>
                <SelectComponent
                  key={'editOP' + triggerAdj}
                  defaultValue={adjustmentOptions().find(
                    x => x.value === modalDetailNews.editNovedadID,
                  )}
                  value={adjustmentOptions().find(x => x.value === modalDetailNews.editNovedadID)}
                  onChange={e =>
                    setModalDetailNews({
                      ...modalDetailNews,
                      editNovedad: e.label,
                      editNovedadID: e.value,
                    })
                  }
                  options={adjustmentOptions()}
                  placeholder='Seleccionar...'
                  styles={customSelectNewDark}
                  maxHeight={22}
                />
              </div>

              <div className='d-flex flex-column'>
                <label className={`${tableStyles.crudModalLabel}`}>Cantidad</label>
                <NumberFormat
                  key={'editIn' + triggerAdj}
                  allowNegative={false}
                  placeholder='$5.000'
                  className={IndividualStyles.registerInputsBlue}
                  thousandsGroupStyle='thousand'
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  prefix={'$'}
                  value={modalDetailNews.editQTY}
                  onValueChange={values => {
                    const { value } = values;
                    setModalDetailNews({
                      ...modalDetailNews,
                      editQTY: value,
                    });
                  }}
                />
              </div>
            </div>
          ) : (
            <div className='d-flex flex-column mb-2'>
              <label className={`${tableStyles.crudModalLabel}`}>Base Gravable</label>
              <NumberFormat
                key={'tax_basis' + triggerAdj}
                allowNegative={false}
                placeholder='$5.000'
                className={IndividualStyles.registerInputsBlue}
                thousandsGroupStyle='thousand'
                thousandSeparator={'.'}
                decimalSeparator={','}
                prefix={'$'}
                value={modalDetailNews.tax_basis}
                onValueChange={values => {
                  const { value } = values;
                  setModalDetailNews({
                    ...modalDetailNews,
                    tax_basis: value,
                  });
                }}
              />
            </div>
          )}
        </ModalNew>
      </div>
    </div>
  );

  return detailShowEmployee.show ? (
    <DetailEmployee
      showEmployeeDetails={detailShowEmployee}
      setDetailShowEmployee={setDetailShowEmployee}
      payrollSheet={props?.payroll_sheet}
      getDetailVariable={getDetailVariable}
      triggers={setTrigger}
      comment={fetchColorFromEndpoint}
    />
  ) : (
    myRender
  );
}
export default DetailPayrollTemplate;