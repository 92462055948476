import { Fragment, useEffect, useState } from 'react';

import check from '../../assets/img/icons/CheckMark.svg';
import deny from '../../assets/img/icons/extraBtnRedX.svg';
import PENDING from '../../assets/img/icons/Alert.svg';
import APPROVED from '../../assets/img/icons/Check Mark.svg';
import REJECTED from '../../assets/img/icons/extraBtnRedX.svg';
import PROCESSED from '../../assets/img/icons/grayCheckNew.svg';
import threeDots from '../../assets/img/icons/threeDots.svg';

import Pagination from 'react-js-pagination';
import Search from '../../assets/img/icons/lupa.svg';
import GenericTableNew from '../Layouts/GenericTableNew';
import { useSelector, useDispatch } from 'react-redux';
import 'reactjs-popup/dist/index.css';
import '../TableUsers/tableUserStyle.scss';
import tableStyles from '../Layouts/tableStyle.module.scss';
import { customSelectNew } from '../../components/Layouts/react-select-custom';
import paginationStyles from '../Layouts/pagination.module.scss';
import { Col, OverlayTrigger, Row } from 'react-bootstrap';
import Select from 'react-select';
import CustomPopupExtend from '../Popup/customPopUpExtends';
import ModalNew from '../Layouts/ModalNew';
import {
  getDetail,
  getOverTimeList,
  updateOverTimeStatus,
} from '../../actions/approvedNewsActions';
import { costcenter_get } from '../../actions/costcenterActions';
import { formatToRcSelect, genericDownloadExcel, loader, message } from '../../helpers/helpers';
import NumberFormat from 'react-number-format';
import { customSwaltAlert } from '../../helpers/customSwaltAlert';
import { useGetMethod, useHasPermissionByTagModule } from '../../Hooks';
import Excel from '../../assets/img/icons/excel.svg';
import Tooltip from 'react-bootstrap/Tooltip';
import { useHistory } from 'react-router-dom';
import { getCheckContractStatus } from '../../actions/checkContractStatusActions';

export const OverTimeList = () => {
  const history = useHistory();
  const store = useSelector(state => state);
  const myPermission = store.loginReducer.currentAccount?.profile?.permission?.find(
    x => x.functionality?.prefix === 'OvrTime',
  );
  const { trigger: getDocExcel, load: loaderGetDocExcel } = useGetMethod();
  const idAccount = store.loginReducer.user_data.id;
  const idEnterprise = store.loginReducer.currentAccount.id;
  const token = store.loginReducer.Authorization;
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const arrConCenter = store.costcenterReducer?.cc;
  const [trigger, setTrigger] = useState(0);
  const [elemDetail, setElemDetail] = useState({});
  const [arrDetail, setArrDetail] = useState([]);
  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
    entity_account: idEnterprise,
    search: '',
    status: undefined,
    dateFrom: '',
    dateUntil: '',
    area: undefined,
  });
  const formattedConCenter = formatToRcSelect(arrConCenter, 'id', 'description');
  const arrOverTime = store.approvedNewsReducer?.overTimeList;
  useEffect(() => {
    dispatch(getOverTimeList(filters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.page, trigger]);

  useHasPermissionByTagModule({module: 'nomina', hasRead: myPermission?.read})

  useEffect(() => {
    dispatch(
      costcenter_get({
        eaccount: store.loginReducer.currentAccount.id,
        active: 1,
      }),
    );
    dispatch(getCheckContractStatus({
      history,
      params: {
        account: store.loginReducer.currentAccount.id,
      }
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateExcel = () =>{
    if (!filters.dateFrom && !filters.dateUntil){
      customSwaltAlert({
        showCancelButton: false,
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Ingrese rango de fechas para descargar histórico de horas extra no regulares',
        confirmButtonText: 'Aceptar',
      })
      return false
    }
    return true
  }

  const downloadExcelDoc = () => {
    if(!validateExcel()){
      return
    }
    getDocExcel({
      url: '/payroll/surcharge/download-no-regular-hours/',
      method: 'GET',
      objFilters: {
        eaccount: store?.loginReducer?.currentAccount?.id,
        dateFrom: filters.dateFrom,
        dateUntil: filters.dateUntil,
      },
      token: token,
      succesAction: results => {
        genericDownloadExcel(results?.results?.base64, results?.results?.filename);
        customSwaltAlert({
          showCancelButton: false,
          icon: 'success',
          title: 'Excel descargado',
          text: results.message,
          confirmButtonText: 'Aceptar',
        });
      },
      doAfterException: results => {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: results.message,
          confirmButtonText: 'Aceptar',
        });
      },
    });
  };

  const renderElement = elem => {
    return (
      <tr key={elem.id}>
        <td className='col-md-2 text-start' style={{ paddingLeft: '0.5rem' }}>
          {elem.collaborator}
        </td>
        <td className='col-md-2 text-start'>&nbsp; {elem.job_title}</td>
        <td className='col-md-1 text-start'>{elem.area}</td>
        <td className='col-md-2 text-center'>{elem.hours}</td>
        <td className='col-md-2 text-center'>{elem.dateStart}</td>

        <td
          title={
            elem.status === 'pending'
              ? 'Pendiente'
              : elem.status === 'approved'
                ? 'Aprobado'
                : elem.status === 'rejected'
                  ? 'Rechazado'
                  : elem.status === 'processed' && 'Procesado'
          }
          className='col-md-2 text-center'
        >
          <span>
            <img
              alt='status'
              src={
                elem.status === 'pending'
                  ? PENDING
                  : elem.status === 'approved'
                    ? APPROVED
                    : elem.status === 'rejected'
                      ? REJECTED
                      : elem.status === 'processed' && PROCESSED
              }
            ></img>
          </span>
        </td>

        <td className='col-md-1 text-center cursorPointer'>
          <div>
          {elem.status !== 'pending' && (
            <CustomPopupExtend
              noHover
              triggerSrc={threeDots}
              showDetails={!!myPermission?.read}
              textDetails='Ver detalle'
              showDetailsEvent={() => handleClickDetail(elem)}
              showUndo={
                !!myPermission?.edit && elem.status === 'pending'
                  ? false
                  : elem.status === 'approved'
                    ? true
                    : elem.status === 'rejected'
                      ? true
                      : elem.status === 'processed' && false
              }
              undoEvent={() => handleUndo(elem)}
            />
          )}
          </div>
        </td>
      </tr>
    );
  };

  const handleUndo = async elem => {
    try {
      customSwaltAlert({
        icon: 'warning',
        title: '¿Está seguro?',
        text: `Se va deshacer la hora extra: ${elem.job_title}`,
      }).then(result => {
        if (result.isConfirmed) {
          const send = async () => {
            try {
              const result = await updateOverTimeStatus(
                {
                  status_detail: 'pending',
                  reviewed_by: idAccount,
                  id: elem.id,
                },
                token,
              );

              if (result.success) {
                setTrigger(trigger + 1);
                return message('success', 'Éxito', result.message);
              } else {
                return message('error', 'Ha ocurrido un error', result.message);
              }
            } catch (error) {
              message('error', 'Error', error.message);
            }
          };
          send();
        }
      });
    } catch (error) {
      console.error(error);
      return message('error', 'Error', error.message);
    }
  };

  const handleAuth = async elem => {
    try {
      customSwaltAlert({
        icon: 'warning',
        title: '¿Está seguro?',
        text: `Se aprobarán las horas extra registradas por: ${elem.collaborator}`,
      }).then(result => {
        if (result.isConfirmed) {
          const send = async () => {
            try {
              const result = await updateOverTimeStatus(
                {
                  status_detail: 'approved',
                  reviewed_by: idAccount,
                  id: elem.id,
                },
                token,
              );

              if (result.success) {
                setShowModal(false);
                setTrigger(trigger + 1);
                return message('success', 'Aprobación exitosa', result.message);
              } else {
                return message('warning', 'Vuelve a intentarlo', result.message);
              }
            } catch (error) {
              message('warning', 'Vuelve a intentarlo', result.message);
            }
          };
          send();
        }
      });
    } catch (error) {
      console.error(error);
      return message('warning', 'Vuelve a intentarlo', error.message);
    }
  };
  const handleDeny = async elem => {
    try {
      customSwaltAlert({
        icon: 'warning',
        title: '¿Está seguro?',
        text: `Se denegarán las horas extra registradas por: ${elem.collaborator}`,
      }).then(result => {
        if (result.isConfirmed) {
          const send = async () => {
            try {
              const result = await updateOverTimeStatus(
                {
                  status_detail: 'rejected',
                  reviewed_by: idAccount,
                  id: elem.id,
                },
                token,
              );

              if (result.success) {
                setShowModal(false);
                setTrigger(trigger + 1);
                return message('success', 'Denegado exitosamente', result.message);
              } else {
                return message('warning', 'Vuelve a intentarlo', result.message);
              }
            } catch (error) {
              message('warning', 'Vuelve a intentarlo', result.message);
            }
          };
          send();
        }
      });
    } catch (error) {
      console.error(error);
      return message('error', 'Error', error.message);
    }
  };

  const listElem = () => {
    let elemMap;
    if (arrOverTime !== undefined) {
      const elem2 = arrOverTime;
      if (elem2.length > 0) {
        elemMap = elem2.map(elem => {
          return renderElement(elem);
        });
      }
    }
    return elemMap;
  };

  const renderHeaders = [
    <th key={1} className='text-start'>
      &nbsp; Colaborador
    </th>,
    <th key={2} className='text-start'>
      &nbsp; Cargo
    </th>,
    <th key={3} className='text-start'>
      Área
    </th>,
    <th key={4} className='text-center'>
      Horas
    </th>,
    <th key={5} className='text-center'>
      Fecha
    </th>,
    <th key={6} className='text-center'>
      Estado
    </th>,
    <th key={7}>&nbsp;</th>,
  ];

  const handleClickDetail = async elem => {
    const result = await getDetail(
      {
        entity_account: idEnterprise,
        id: elem.id,
      },
      token,
    );
    if (result?.success) {
      setArrDetail(result?.results);
    }

    setElemDetail(elem);
    setShowModal(true);
  };

  const handleSearch = e => {
    e.preventDefault();
    setFilters({ ...filters, page: 1 });
    setTrigger(trigger + 1);
  };

  const statusOptions = [
    { value: '', label: 'Seleccionar...' },
    { value: 'pending', label: 'Pendiente' },
    { value: 'approved', label: 'Aprobado' },
    { value: 'rejected', label: 'Rechazado' },
    { value: 'processed', label: 'Procesado' },
  ];
  return (
    <>
      {(store.approvedNewsReducer.loader || loaderGetDocExcel || store.checkContractStatusReducer.loading) && loader}

      <ModalNew
        show={showModal}
        hideCancelButton={true}
        onHide={() => setShowModal(false)}
        title={'Horas extra registradas'}
        subtitle={
          elemDetail.status === 'pending'
            ? 'En espera'
            : elemDetail.status === 'approved'
              ? 'Aprobado'
              : elemDetail.status === 'rejected'
                ? 'Rechazado'
                : elemDetail.status === 'processed' && 'Procesado'
        }
        subtitleStyle={
          elemDetail.status === 'pending'
            ? 'text-warning'
            : elemDetail.status === 'approved'
              ? 'text-success'
              : elemDetail.status === 'rejected'
                ? 'text-danger'
                : elemDetail.status === 'processed' && 'text-secondary'
        }
        extraButtons={
          elemDetail.status === 'pending' && [
            {
              name: 'Denegar',
              icon: deny,
              event: () => {
                handleDeny(elemDetail);
              },
            },
            {
              name: 'Autorizar',
              icon: check,
              event: () => {
                handleAuth(elemDetail);
              },
            },
          ]
        }
        size={'750'}
      >
        <Row className={`d-flex ${tableStyles.bgColorClearBlue} pb-3 rounded`}>
          <h6 className={`${tableStyles.darkBlueText} pt-1`}>
            <b>Colaborador</b>
          </h6>
          <Col xs={6}>
            <p className={`${tableStyles.crudModalLabel} `}>Nombre</p>
            <input
              placeholder='Escribe...'
              className='register-inputs text-secondary'
              value={elemDetail.collaborator}
              disabled
            />
          </Col>
          <Col xs={6}>
            <p className={`${tableStyles.crudModalLabel} `}>Cargo</p>
            <input
              placeholder='Escribe...'
              className='register-inputs text-secondary'
              value={elemDetail.job_title}
              disabled
            />
          </Col>
          <Col xs={6}>
            <p className={`${tableStyles.crudModalLabel} `}>Fecha/Hora de entrada</p>
            <input
              placeholder='Escribe...'
              className='register-inputs text-secondary'
              value={elemDetail.dateStartTime}
              disabled
            />
          </Col>
          <Col xs={6}>
            <p className={`${tableStyles.crudModalLabel} `}>Fecha/Hora de salida</p>
            <input
              placeholder='Escribe...'
              className='register-inputs text-secondary'
              value={elemDetail.dateEndTime}
              disabled
            />
          </Col>
        </Row>

        <Row className='d-flex mb-4 mt-2' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
          {arrDetail?.length > 0 &&
            arrDetail.map((e, i) => {
              return (
                <Fragment key={i}>
                  <Col xs={4}>
                    <p className={`${tableStyles.crudModalLabel} `}>Tipo</p>
                    <input
                      placeholder='Escribe...'
                      className='register-inputs text-secondary'
                      value={e.description}
                      disabled
                    />
                  </Col>
                  <Col xs={4}>
                    <p className={`${tableStyles.crudModalLabel} `}>No. de horas</p>
                    <input
                      placeholder='Escribe...'
                      className='register-inputs text-secondary'
                      value={e.hours}
                      disabled
                    />
                  </Col>

                  <Col xs={4}>
                    <p className={`${tableStyles.crudModalLabel} `}>Valor</p>
                    <NumberFormat
                      placeholder='Escribe...'
                      className='register-inputs text-secondary'
                      disabled
                      value={e.hour_value}
                      prefix='$'
                      thousandSeparator
                    ></NumberFormat>
                  </Col>
                </Fragment>
              );
            })}
        </Row>
      </ModalNew>

      <div className={tableStyles.container}>
        <div className={tableStyles.tableArea}>
          <h1 className={tableStyles.title}>Aprobar horas extras</h1>

          {!!myPermission?.read && (
            <>
              <Row>
                <Col className='d-block' xs={2}>
                  <p className={`${tableStyles.crudModalLabel}`}>Estado</p>
                  <Select
                    noOptionsMessage={() => 'No hay datos'}
                    onChange={e => setFilters({ ...filters, status: e.value })}
                    options={statusOptions}
                    className='text-secondary'
                    placeholder={'Seleccionar...'}
                    value={statusOptions.find(x => x.value === filters?.status)}
                    styles={customSelectNew}
                  ></Select>
                </Col>
                <Col className='d-block' xs={2}>
                  <p className={`${tableStyles.crudModalLabel}`}>Área</p>
                  <Select
                    noOptionsMessage={() => 'No hay datos'}
                    onChange={e => setFilters({ ...filters, area: e.value })}
                    options={formattedConCenter}
                    className='text-secondary'
                    placeholder={'Seleccionar...'}
                    value={formattedConCenter.find(x => x.value === filters?.area)}
                    styles={customSelectNew}
                  ></Select>
                </Col>
                <Col className='d-block' xs={2}>
                  <p className={`${tableStyles.crudModalLabel}`}>Fecha desde</p>
                  <input
                    onChange={e => setFilters({ ...filters, dateFrom: e.target.value })}
                    type='date'
                    placeholder='Escribe...'
                    className='register-inputs text-primary  fw-bold'
                    name='position'
                  />
                </Col>
                <Col className='d-block' xs={2}>
                  <p className={`${tableStyles.crudModalLabel}`}>Hasta</p>
                  <input
                    onChange={e => setFilters({ ...filters, dateUntil: e.target.value })}
                    type='date'
                    placeholder='Escribe...'
                    className='register-inputs text-primary  fw-bold'
                    name='position'
                  />
                </Col>
                <Col className='d-block' xs={3}>
                  <div className='pt-4'>
                    <form onSubmit={e => handleSearch(e)} className='d-flex align-items-end'>
                      <input
                        className={`${tableStyles.SearchNew} mr-2 `}
                        onChange={e => setFilters({ ...filters, search: e.target.value })}
                        name='search'
                        type='text'
                        placeholder='Buscar...'
                      />
                      <img
                        src={Search}
                        className='cursorPointer'
                        width={'24px'}
                        height={'24px'}
                        style={{ marginBottom: '2px' }}
                        alt='Search icon'
                        onClick={e => handleSearch(e)}
                      />
                    </form>
                  </div>
                </Col>
                <Col className='d-flex justify-content-center ' xs={1}>
                  <OverlayTrigger
                    placement='bottom'
                    delay={{ show: 250, hide: 250 }}
                    overlay={
                      <Tooltip className={`${tableStyles.darkerGrayText}`}>No regulares</Tooltip>
                    }
                  >
                    <button onClick={() => downloadExcelDoc()} style={{ position: 'relative' }}>
                      <img style={{ position: 'absolute', bottom: 0 }} src={Excel} alt='' />
                    </button>
                  </OverlayTrigger>
                </Col>
              </Row>

              <GenericTableNew headers={renderHeaders} dark={true}>
                {listElem()}
              </GenericTableNew>

              <div className={paginationStyles.wrapper}>
                <p className={`${paginationStyles.paginationText} text-secondary`}>
                  Pag. {store.approvedNewsReducer.overTimeList ? filters.page : ''}
                  {' de '}
                  {Math.ceil(store.approvedNewsReducer.rowTotal / filters.perpage)
                    ? Math.ceil(store.approvedNewsReducer.rowTotal / filters.perpage)
                    : '1'}{' '}
                  ({store.approvedNewsReducer.rowTotal} encontrados)
                </p>
                <Pagination
                  activePage={filters.page}
                  itemsCountPerPage={filters.perpage}
                  totalItemsCount={store.approvedNewsReducer.rowTotal}
                  pageRangeDisplayed={5}
                  onChange={e => setFilters({ ...filters, page: e })}
                  itemClassPrev={paginationStyles.itemClassPrev}
                  itemClassNext={paginationStyles.itemClassNext}
                  itemClassFirst={paginationStyles.itemClassFirst}
                  itemClassLast={paginationStyles.itemClassLast}
                  itemClass={paginationStyles.itemClass}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
