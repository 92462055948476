// --------------- components 🏴󠁧󠁢󠁥󠁮󠁧   ----------------//
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import GenericTableScroll from "../Layouts/GenericTableScroll";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "@material-ui/core";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import PENDING from "../../assets/img/icons/Alert.svg";
import PENDINGREVIEW from "../../assets/img/icons/Reloj.svg";
import FAILED from "../../assets/img/icons/alert-circle.svg";
import DOC from "../../assets/img/icons/pdf.svg";
import APPROVED from "../../assets/img/icons/Check Mark.svg";
import PRE_APPROVED from '../../assets/img/icons/Check Mark.svg';
import REJECTED from "../../assets/img/icons/extraBtnRedX.svg";
import PROCESSED from "../../assets/img/icons/grayCheckNew.svg";
import threeDots from "../../assets/img/icons/threeDots.svg";
import CustomPopupStyles from "../../components/Popup/customPopup.module.scss";
import iconProvisions from "../../assets/img/icons/cloneIcon.svg";
import Lupa from "../../assets/img/icons/lupa.svg";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import IndividualStyles from "../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss";

import { getListPayrollAuthorization } from "../../actions/accountingMovementsActions";
import DetailPayrollTemplate from "../GeneratePayroll/DetailPayrollTemplate";
import {
  convertMoneyFormat,
  generateId,
  message,
} from "./../../helpers/helpers";
import CustomPopupExtend from "../Popup/customPopUpExtends";
import { usePostMethod } from "../../Hooks";
import { customSwaltAlert } from "../../helpers";

function PayrollAuthorization() {
  const dispatch = useDispatch();
  const storage = useSelector((state) => state);
  const [trigger, setTrigger] = useState(1);
  const [Detail, setDetail] = useState({ show: false });
  const [info, setInfo] = useState({
    search: "",
    eaccount: storage.loginReducer.currentAccount.id,
    dateFrom: "",
    dateUntil: "",
    module: "payrollAuth",
  });

  const { trigger: getAccountingReceipt, load: accountingReceiptLoader } =
    usePostMethod();

  const onPostPayrollOption = ({ type, id }) => {
    const token = storage.loginReducer.Authorization;
    const url = type === 'payroll' ? '/payroll/create-payroll-voucher/' : '/payroll/create-provision-voucher/';

    getAccountingReceipt({
      url,
      token,
      method: 'POST',
      body: {
        payrollSheetId: id,
        eaccount: storage.loginReducer.currentAccount.id,
      },

      succesAction: (result) => {
        customSwaltAlert({
          showCancelButton: false,
          icon: "success",
          title: result?.message,
          confirmButtonText: "Aceptar",
        });
      },
      doAfterException: (result) => {
        customSwaltAlert({
          showCancelButton: false,
          icon: "warning",
          title: "Intenta de nuevo",
          text: result?.message,
          confirmButtonText: "Aceptar",
        });
      },
    });
  };

  useEffect(() => {
    dispatch(getListPayrollAuthorization(info));
  }, [trigger, dispatch, info]);

  const header = [
    <div key={generateId()} className={`col-2 px-2 text-start`}>
      Mes
    </div>,
    <div key={generateId()} className={`col-1 text-center`}>
      Año
    </div>,
    <div key={generateId()} className={`col-1 text-center`}>
      Rango
    </div>,
    <div key={generateId()} className={`col-1 text-center`}>
      Fecha sugerida
    </div>,
    <div key={generateId()} className={`col-2 text-center`}>
      No. de empleados
    </div>,
    <div key={generateId()} className={`col-3 text-end`}>
      Total
    </div>,
    <div key={generateId()} className={`col-1 text-center`}>
      Estado
    </div>,
    <div key={generateId()} className={`col-1`}>
      &nbsp;
    </div>,
  ];

  const renderList = () => {
    let tempList = [];
    if (Array.isArray(storage.accountingMovementsReducer.listPayrollAuthorization)) {
      storage.accountingMovementsReducer.listPayrollAuthorization.forEach(item => {
        tempList.push(
          <section2 className={`d-flex`}>
            <div className='col-2 text-start px-2' style={{ color: '#005DBF', fontWeight: 'bold' }}>
              {item.monthES}
            </div>
            <div className='col-1'>{item.year}</div>
            <div className='col-1'>{item.range}</div>
            <div className='col-1'>
              {item.date.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1')}
            </div>
            <div className='col-2 '>{item.employeeCount}</div>
            <div className='col-3 text-end'>
              {item.totalAmount
                ? convertMoneyFormat(Number(item.totalAmount).toFixed(2), '.')
                : '-'}
            </div>
            <div className='col-1'>
              <Tooltip title={item.statusName} arrow>
                <img
                  alt={'status'}
                  height={18}
                  style={{ ...(item.status === 'pre-approved' ? { filter: 'grayscale(100%) brightness(110%)' } : {}) }}
                  src={
                    item.status === 'review'
                      ? PENDING
                      : item.status === 'approved'
                        ? APPROVED
                        : item.status === 'pre-approved'
                          ? PRE_APPROVED
                          : item.status === 'rejected'
                            ? REJECTED
                            : item.status === 'failed_review'
                              ? FAILED
                              : item.status === 'under_review'
                                ? PENDINGREVIEW
                                : item.status === 'processed' && PROCESSED
                  }
                ></img>
              </Tooltip>
            </div>
            <div className='col-1 cursorPointer'>
              <CustomPopupExtend
                showDetails={true}
                noHover
                triggerSrc={threeDots}
                showDetailsEvent={() => {
                  setDetail({
                    show: true,
                    selectedPayroll: item.id,
                    status: item.statusName,
                    paymentDate: item?.date,
                    month: item.monthES,
                    year: item.year,
                    day: item.date.split('-')[2],
                  });
                }}
                textDetails='Ver Detalle'
                extraButtons={
                  item.status === 'failed_review'
                    ? [
                      {
                        text: 'Ver fallo',
                        icon: iconProvisions,
                        class: CustomPopupStyles.popUpGeneric,
                        event: () => {
                          message(
                            'warning',
                            'Intenta de nuevo',
                            item?.cron_log_message,
                            undefined,
                            true,
                          );
                        },
                      },
                    ]
                    : item.status === 'approved'
                      ? [
                        {
                          text: 'Comprobante de Nómina',
                          icon: DOC,
                          class: CustomPopupStyles.popUpGeneric,
                          event: () => {
                            onPostPayrollOption({ id: item.id, type: 'payroll' });
                          },
                        },
                        {
                          text: 'Comprobante de Provisiones',
                          icon: DOC,
                          class: CustomPopupStyles.popUpGeneric,
                          event: () => {
                            onPostPayrollOption({ id: item.id, type: 'provision' });
                          },
                        },
                      ]
                      : []
                }
              />
            </div>
          </section2>,
        );
      });
    }
    return tempList;
  };
  const handleSumit = (e) => {
    setInfo({
      ...info,
      dateFrom: e.target.value,
    });
  };
  const handleSumit1 = (e) => {
    setInfo({
      ...info,
      dateUntil: e.target.value,
    });
    setTrigger(trigger + 1);
  };

  const myRender = (
    <div
      className={` ml-5 ${tableStyles.container}`}
      style={{ marginRight: "3rem" }}
    >
      {(storage.accountingMovementsReducer.authorization_loading ||
        accountingReceiptLoader) && (
        <div className="loading">
          <Loader type="Oval" color="#003f80" height={100} width={100} />
        </div>
      )}
      <div className="d-flex">
        <div className={tableStyles.title}>
          <h1> Autorización de nómina </h1>
        </div>
      </div>
      <Row className="d-flex align-items-end">
        <Col xs={2}>
          <span className={tableStyles.crudModalLabel}>Fecha desde</span>
          <input
            className={IndividualStyles.registerInputsBlue}
            type="date"
            value={info.dateFrom}
            onChange={(e) => handleSumit(e)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setTrigger(trigger + 1);
              }
            }}
          ></input>
        </Col>
        <Col xs={2}>
          <span className={tableStyles.crudModalLabel}>Hasta</span>
          <input
            className={IndividualStyles.registerInputsBlue}
            value={info.dateUntil}
            placeholder="Buscar por No de empleados, Estado..."
            type="date"
            onChange={(e) => handleSumit1(e)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setTrigger(trigger + 1);
              }
            }}
          ></input>
        </Col>
        <Col xs={1}>
          <img
            className="cursorPointer"
            height="24px"
            src={Lupa}
            alt=""
            onClick={() => setTrigger(trigger + 1)}
          />
        </Col>
      </Row>
      <GenericTableScroll
        headers={header}
        dark={true}
        body={renderList()}
        typeHead={"2"}
      ></GenericTableScroll>
    </div>
  );

  return Detail.show ? (
    <DetailPayrollTemplate
      fromAuth={true}
      payroll_sheet={Detail.selectedPayroll}
      details={Detail}
      showModal={Detail.show}
      selectedPayroll={Detail.selectedPayroll}
      show={Detail.show}
      status={Detail.status}
      month={Detail.month}
      year={Detail.year}
      day={Detail.day}
      setDetails={setDetail}
      module={{ status: 'pre-approved', prefix: 'payrollAuth' }}
    />
  ) : (
    myRender
  );
}
export default PayrollAuthorization;
