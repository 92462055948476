//All actions for Login component
import { USER_LOGIN_SUCCESS, LOGOUT_SUCCESS, SHOW_ACCOUNT_INFO, SAVE_USER_INFO, GET_ALL_CATEGORIES, SITE_SELECTED, ON_CHECK_CONTRACT_STATUS_CLEAR } from "./actionTypes";
import {   ACCOUNT_SELECT_SAVE, ACCOUNT_SELECT_GET, ACCOUNT_LOADING, CATEGORY_SELECT_SAVE} from "./actionTypes";
import { URL_GATEWAY, API_VERSION } from "../helpers/constants";
import { clear } from "redux-localstorage-simple"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { customSwaltAlert } from "../helpers";
const MySwal = withReactContent(Swal)

export const validateUserToken = (token) => {
    return fetch(`${URL_GATEWAY}${API_VERSION}/security/validate`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: token,
        },
    })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(res => {
            return res;
        })
        .catch(error => {
            console.error('Error fetching data:', error);
            throw error;
        });
}

export const verifyAccessToken = (token) => (dispatch,getState) => {
    const token = getState().loginReducer.Authorization
    fetch(`${URL_GATEWAY}${API_VERSION}/security/validate`,{
        headers:{
            Accept: "application/json",
                "Content-Type": "application/json",
            Authorization:token
        }
    })
    .then(response => response.json())
    .then(res => {
        if(!res.status || res.status > 300){
            dispatch(logOutUser())
        }
    })
    .catch(err => console.error(err,"ERROR"))

}

export const validateUser = (user, pass) => (dispatch,getState)  => {
    if(user && pass){
        var data = {
            email:user,
            password:pass
        }
        fetch(`${URL_GATEWAY}${API_VERSION}/users/login/`,{
            method: "POST",
            body: JSON.stringify(data),
            mode:'cors',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            }
        })
        .then(response => response.json())
        .then(data => {
            const {status, message, user_data, access_token} = data

            if( status>300 || (!status && !access_token) ){
                return MySwal.fire({
                    icon:'error',
                    title: "Error",
                    text: message,
                })
            }
            if(!user_data.have_password){
                return MySwal.fire({
                    icon:'warning',
                    title: "Correo enviado",
                    text: "Se envío un correo electrónico para restablecer su contraseña."
                })
            }else{
                dispatch({
                    type:USER_LOGIN_SUCCESS,
                    payload: user_data,
                    Authorization:access_token
                })
                dispatch({
                    type:SAVE_USER_INFO,
                    payload: user_data,
                })
            }
        })
        .catch((err)=>{
            console.error(err)
            MySwal.fire({
                icon:'error',
                title: "Error al conectar",
                text: "Ocurrió un error al intentar conectarse, por favor intente de nuevo."
              })
        })
    }else{
        MySwal.fire({
            icon:'error',
            title: "Error al ingresar",
            text: "Debe ingresar correo y contraseña para acceder al sistema."
          })
    }
}

export const logOutUser = () => (dispatch,getState) => {
    clear({
        states:['loginReducer','profileReducer', 'userReducer','checkContractStatusReducer']
    })
    dispatch({
        type:SHOW_ACCOUNT_INFO,
        payload:false
    })
    dispatch ({
      type: LOGOUT_SUCCESS,
    });

    dispatch({
      type: ON_CHECK_CONTRACT_STATUS_CLEAR,
    });

  }



  export const selectAccount = (userid, onLogout) => (dispatch,getState)  => {
    dispatch({
        type:ACCOUNT_LOADING,
        error: false,
        loading: true
    })
    const token = getState().loginReducer.Authorization;
    fetch(`${URL_GATEWAY}${API_VERSION}/composite/selectaccount/${userid}`,{
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
    })
    .then(response => response.json())
    .then(data => {
        const {status, accounts} = data
        if( status>300 ){
            dispatch({
                type:ACCOUNT_LOADING,
                error: true,
                loading: false
            });
            return customSwaltAlert({
                icon:'warning',
                title: "Su sesión ha finalizado",
                text: 'Vuelva a iniciar sesión nuevamente para continuar',
                confirmButtonText:'Aceptar',
                showCancelButton: false
            }).then(()=>{
                onLogout()
            })
        }else{
            dispatch({
                type:ACCOUNT_SELECT_GET,
                payload: accounts,
                loading: false
            })
        }
    })
    .catch((err)=>{
        console.error(err)
        dispatch({
            type:ACCOUNT_LOADING,
            error: true,
            loading: false
        });
    })
}

export const saveAccount = (account, doAfter) => (dispatch,getState)  => {

    dispatch({
        type:ACCOUNT_SELECT_SAVE,
        payload: account
    });

    doAfter();


    // return MySwal.fire({
    //     icon:'success',
    //     title: "selecciono cuenta "+account.name+" ",
    // });
}
export const saveCategory = (category, doAfter) => (dispatch, getState) =>{
    dispatch({
        type:ACCOUNT_LOADING,
        error: false,
        loading: true
    });


    dispatch({
        type:CATEGORY_SELECT_SAVE,
        category: category,
    });

    setTimeout(()=>{
        dispatch({
            type:ACCOUNT_LOADING,
            error: false,
            loading: false
        });
    },1000)


    if(!!doAfter){
    doAfter();


}
    // return MySwal.fire({
    //     icon:'success',
    //     title: "selecciono el módulo de "+category+" ",
    // });
}
export const getCategories = (id_profile) => (dispatch, getState) => {
    dispatch({
        type:GET_ALL_CATEGORIES,
        loadingCategories: true
    })
  const token = getState().loginReducer.Authorization;
  fetch(
    `${URL_GATEWAY}${API_VERSION}/security/permission/category/?id_profile=` + id_profile,
     {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  ).then((response) => response.json())
  .then((res) => {
      const {row_total, all_Categories,new_category} = res;
      dispatch({
          type: GET_ALL_CATEGORIES,
          total: row_total,
          all_categories : all_Categories,
          newCategories: new_category,
          loadingCategories: false,
        });
    })
    .catch((err) => {
        console.error(err.message);
    });
}
export const forgotPassword = (account) => (dispatch,getState) => {
    fetch(`${URL_GATEWAY}${API_VERSION}/composite/forgotPassword/`,{
        method: "POST",
        body: JSON.stringify(account),
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        }
    })
    .then(response => response.json())
    .then(res => {
        if(res.success){
            MySwal.fire({
                icon:'success',
                title:'¡Correo enviado!',
                text:`${account.email}`
            })
        }else{
            MySwal.fire({
                icon:'error',
                title:'Error',
                text:`${res.message}`
            })
        }
    })
    .catch((err)=>{
        console.error(err);
        MySwal.fire({
            icon:'error',
            title:'Error al cambiar contraseña',
            text:'No se pudo cambiar la contraseña',
            footer:'Si el problema persiste comuníquese con un asesor.'
        })
    })
}


export const setSite = () => (dispatch, getState) =>{
    dispatch({
        type: SITE_SELECTED,
        payload: {
            siteId:"",
            siteName: ""
        }})
}