import React from "react";

//import "../TableUsers/tableUserStyle.scss";
import tableStyles from "./genericTable.module.scss";

function GenericTableNew (props){

    return (
      <table
        className={
          `table
          ${props.lowerCase ? tableStyles.genericTableLower : tableStyles.genericTable}
          ${props.oneHeadDarkBlue ? tableStyles.oneHeadDarkBlue : ""}
          ${props.treeHeadDarkBlue ? tableStyles.treeHeadDarkBlue : ""}
          `
        }
        style={props.style}
        >
        <thead className={`${tableStyles.thead} ${props.clearHeader ? "" : props.dark ? tableStyles.theadDark : tableStyles.theadLight}`} style={props?.styleHead}>
          <tr className={`${props.fontFamilyTable ? props.fontFamilyTable : ""}`}>{props.headers}</tr>
        </thead>
          <tbody style={props?.styleBody}>
            {props?.children || props?.body}
          </tbody>
          {!!props.footer && (
            <tfoot style={props?.styleFooter}>
              {props.footer}
            </tfoot>
          )}
      </table>
    );
}

export default GenericTableNew;