import { ON_CHECK_CONTRACT_STATUS, ON_CHECK_CONTRACT_STATUS_CLEAR } from "../actions/actionTypes";

const initialState = {
    status: false,
    contractsAccounts: [],
    loading: false
};
export const checkContractStatusReducer = (state = initialState, action) => {
    switch (action.type) {
        case ON_CHECK_CONTRACT_STATUS:
            return {
                ...state,
                ...(action.status !== undefined && { status: action.status }),
                ...(action.contractsAccounts !== undefined && { contractsAccounts: action.contractsAccounts }),
                ...(action.loading !== undefined && { loading: action.loading }),
            };
        case ON_CHECK_CONTRACT_STATUS_CLEAR:
            return {
                ...initialState
            };
        default:
            return state;
    }
};