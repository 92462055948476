import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import Loader from 'react-loader-spinner';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SignaturePad from 'react-signature-canvas';

import adj from '../../../assets/img/icons/Adjuntar.svg';
import x from '../../../assets/img/icons/Cerrar.svg';
import Agregar from '../../../assets/img/icons/add-check.svg';
import historyIcon from '../../../assets/img/icons/cloneIcon.svg';
import guion from '../../../assets/img/icons/guion.svg';
import Lupa from '../../../assets/img/icons/lupa.svg';
import PdfIcon from '../../../assets/img/icons/pdfIcon.svg';
import triggerSrc from '../../../assets/img/icons/threeDots.svg';
import downGrey from '../../../assets/img/icons/desplegarGris.svg';

import { useGetMethod, usePostMethod } from '../../../Hooks/useFetch';
import { getListInvoices } from '../../../actions/InvoiceReceiptActions';
import { GET_PERIODS } from '../../../actions/actionTypes';
import { getAllConCenter } from '../../../actions/consumptionCenterActions';
import styles from './Contract.module.scss';

import {
  add_annex_contract,
  finishInterships,
  getListContract,
  getPeriods,
  getAccounts,
  getSelectStatus,
  getSelectsDirCont,
  settlement,
  signContract,
  updateContractState,
} from '../../../actions/contractAction';
import { capitalizeWords } from '../../../helpers';
import { customSwaltAlert } from '../../../helpers/customSwaltAlert';
import {
  convertBase64,
  convertDateToISOFormat,
  convertMoneyFormat,
  firtsOfTheMonth,
  formatToRcSelect,
  loader,
  message,
  optionsSelect,
  valueSelect,
} from '../../../helpers/helpers';

import CustomPopupStyles from '../../../components/Popup/customPopup.module.scss';
import GenericTable from '../../Layouts/GenericTableNew';
import ModalNew from '../../Layouts/ModalNew';
import FileInput from '../../Layouts/fileInput';
import { customSelectNew, customSelectNewDark } from '../../Layouts/react-select-custom';
import CustomPopup from '../../Popup/customPopUpExtends';
import SelectComponent from '../../SelectComponent/SelectComponent';
import CreateContract from './CreateContract';

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import paginationStyles from '../../Layouts/pagination.module.scss';
import tableStyles from '../../Layouts/tableStyle.module.scss';
import IndividualStyles from '../../TableIndividualAuction/IndividualAuction_CCS.module.scss';
import Styles from '../Hiring/Contract.module.scss';
import AsyncSelect from 'react-select/async';
import { useHasPermissionByTagModule } from "../../../Hooks";
import { OrdSlideDownButton } from '../../../OrderingModule/OrderingComponents/OrdSlideDownButton';
import { getCheckContractStatus } from '../../../actions/checkContractStatusActions';

const initialEmployeeReplacement = {
  show: false,
  account: undefined,
  job_position: undefined,
  site: undefined,
  new_salary: 0,
  non_cost_income: 0,
  job_type: undefined,
  loader: false,
  contract_id: undefined,
  site_address: "",
  site_name: "",
  valid_from: undefined,
  created_by: undefined
};

const initialReassignStaff = {
  show: false,
  item: undefined,
  superiorId: '',
  usersToReassign: []
}

const strInlineFormat = 'YYYYMMDD';
const strFormat = 'YYYY-MM-DD';

function ListContracts() {
  const hidePopupExtend = () => {
    const ref = document?.querySelector('#popup-root');
    ref?.remove();
  };

  const store = useSelector(state => state);
  const dispatch = useDispatch();
  const counter = useSelector(state => state);
  const periods = counter?.contractReducer?.periods;
  const createdBy = store.loginReducer.user_data.id;
  const [isLoading, setIsLoading] = useState(false);
  const { trigger: getContractTypes } = useGetMethod();

  const [trigger, setTrigger] = useState(1);

  const [docSignSw, setDocSignSw] = useState(false);
  const [endContract, setEndContract] = useState({
    show: false,
    data: {},
    result: {},
  });

  const [otrosSiControl, setOtrosSiControl] = useState({
    show: false,
    job_position: undefined,
    base_salary: '',
    non_const_income: 0,
    start_date: '',
    end_date: '',
    entity_account: counter.loginReducer.currentAccount.id,
    created_by: counter.loginReducer.user_data.id,
  });

  const startDate = moment(otrosSiControl.start_date, 'DD/MM/YYYY');

  const lastDay = useMemo(() => {
    const TODAY = new Date()
    let lastDayOfMonth = new Date(TODAY.getFullYear(), TODAY.getMonth() + 1, 0)

    if (['apprenticeship_contract', 'internship_contract'].includes(endContract?.data?.contract_type)) {
      const endDateISO = convertDateToISOFormat(endContract?.data?.end_date)
      let endDate = new Date(endDateISO)
      endDate.setDate(endDate.getDate() + 10)
      lastDayOfMonth = endDate
    }

    return lastDayOfMonth
  }, [endContract?.data?.contract_type, endContract?.data?.end_date])



  const [inputs, setInputs] = useState({
    created_by: createdBy,
  });
  const [rotate, setRotate] = useState(false);

  const [accounts, setAccounts] = useState([]);
  const [contractTypes, setContractTypes] = useState([]);
  const [temp, setTemp] = useState({});
  const [filters, setFilter] = useState({
    search: '',
    page: 1,
    perpage: 10,
    status: undefined,
    account: counter.loginReducer.currentAccount.id,
    area: undefined,
    position: '',
    contract_type: '',
    dateFrom: '',
    dateUntil: ''
  });
  const token = store.loginReducer.Authorization;
  const [loading, setLoading] = useState(true);
  const [endContractDate, setEndContractDate] = useState(endContract?.data?.end_date);
  const [showDetail, setshowDetail] = useState({
    show: false,
    isEditing: false,
  });
  const [showReassignStaff, setShowReassignStaff] = useState(initialReassignStaff);
  let history = useHistory();
  function handleClick() {
    history.push('/nomina/CrearContrato');
  }
  const myPermission = counter.loginReducer.currentAccount?.profile?.permission?.find(
    x => x.functionality?.prefix === 'hiring',
  );

  const [bonusSalaryOptions, setBonusSalaryOptions] = useState([]);

  const {
    trigger: getJobPosition,
    results: jobPositionsOptions,
    load: jobPositionsLoader,
  } = useGetMethod();
  const { trigger: getSite, results: siteOptions, load: siteLoader } = useGetMethod();
  const { trigger: updateEmployeeReplacement, load: employeeReplacementLoader } = usePostMethod();
  const { trigger: getleadersToReassign, results: leadersToReassignResults, load: leadersToReassignLoader } = useGetMethod();
  const {
    trigger: postStaffInCharge,
    results: staffInChargeResults,
    load: staffInChargeLoader
  } = usePostMethod();
  const {
    trigger: putReassignStaffInCharge,
    load: reassignStaffInChargeLoader
  } = usePostMethod();
  const { trigger: postMarkContinue, load: markContinueLoader } = usePostMethod();
  const [triggerSelect, setTriggerSelect] = useState(0);
  useEffect(() => {
    let formattedBonusSalary = formatToRcSelect(
      periods.last_bonus_salary,
      'id',
      'sem',
      'year',
      'end',
      'year',
    );
    let options = [];
    if (formattedBonusSalary.length > 0) {
      formattedBonusSalary.forEach(option => {
        if (option.value === 0) {
          let label = option.label.split('-')[0];
          options.push({ ...option, label: label, value: '0' });
        } else {
          options.push(option);
        }
      });
      setBonusSalaryOptions(options);
      setTriggerSelect(triggerSelect + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periods?.last_severence]);

  useEffect(() => {
    dispatch(getListContract(filters, () => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.page, trigger]);

  useEffect(() => {
    if (endContract?.data?.end_date) {
      setEndContractDate(convertDateToISOFormat(endContract?.data?.end_date))
    }
  }, [endContract?.data?.end_date, trigger])

  useEffect(() => {
    setInputs(state => ({
      ...state,
      contract: temp?.contract_id,
      employee: temp?.cand_id,
      account: temp?.account,
      end_date: endContractDate
    }))
  }, [temp, endContractDate])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getContractTypes({
          url: '/payroll/contractstypes',
          objFilters: {},
          token: token,
        });

        if (result.success) {
          const mappedResults = result.results.map(item => ({
            value: item.id,
            label: item.description,
          }));
          const optionsWithSelect = [{ value: '', label: 'Seleccionar...' }, ...mappedResults];
          setContractTypes(optionsWithSelect);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [filters, getContractTypes, token]);

  useEffect(() => {
    dispatch(getListContract(filters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDetail]);

  const formattedLayoffs = formatToRcSelect(periods.last_severence, 'id', 'label', 'year');

  const formattedSeveranceInterest = formatToRcSelect(
    periods.last_severance_interest,
    'id',
    'label',
    'year',
  );
  const formattedReasons = formatToRcSelect(periods.reasons, 'id', 'name');
  useEffect(() => {
    dispatch(getSelectStatus({ prefix: 'contracts' }));
    dispatch(
      getAllConCenter({
        eaccount: counter.loginReducer.currentAccount.id,
        lite: 1,
      }),
    );

    dispatch(
      getSelectsDirCont({
        entity_account: counter.loginReducer.currentAccount.id,
        status: 'verified',
      }),
    );
    setshowDetail({ show: false, isEditing: false });
    dispatch(getCheckContractStatus({
      params: {
        account: store.loginReducer.currentAccount.id,
      }
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { load: getValidationLoader, trigger: getValidationMethod } = useGetMethod();


  const getValidation = async () => {
    const isValid = await isValidDate(endContractDate)

    if (!isValid) {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Fecha de finalización inválida',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    }

    getValidationMethod({
      url: `/payroll/pending_settlement`,
      objFilters: {
        eaccount: counter.loginReducer.currentAccount.id,
        idEmployee: endContract?.data?.cand_id,
        endDate: endContractDate,
      },
      token: token,
      doAfterSuccess: res => {
        let days = res?.results?.days > 0 ? true : false;
        handleSubmit(
          days,
          res?.results?.days,
          endContract?.data?.end_date,
          showModal?.selectedContract,
        );
      },
    }).then(err => {
      if (!err.success) {
        customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: err.message,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      }
    });
  };

  const handleSubmit = (_, daysRes) => {
    customSwaltAlert({
      icon: 'warning',
      title: '¿Está seguro?',
      text: `Se finalizará este contrato`,
      confirmButtonText: 'Si, Continuar',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
      denyButtonColor: `rgba(255, 139, 0, 1)`,
    })
      .then(result => {
        if (result.isConfirmed || result.isDenied) {
          let bodyData;
          const send = async () => {
            setIsLoading(true);

            try {
              let settlementResult;

              if (
                result.isDenied ||
                ['apprenticeship_contract', 'internship_contract'].includes(endContract?.data?.contract_type)
              ) {
                bodyData = { end_date: inputs.end_date, id_contract: inputs.contract }
                settlementResult = await finishInterships(bodyData, token)
              } else {
                bodyData = result.isDenied ? { ...inputs, days: daysRes } : inputs
                settlementResult = await settlement(bodyData, token)
                if (settlementResult.success) {
                  setIsLoading(false);
                  history.push({
                    pathname: '/nomina/liquidacion',
                    state: {
                      endContract,
                      result: settlementResult,
                      endContractDate,
                    },
                  });
                }
              }

              if (settlementResult.success) {
                setTrigger(trigger + 1);
                customSwaltAlert({
                  icon: 'success',
                  title: 'Finalizado exitosamente',
                  text: settlementResult.message,
                  confirmButtonText: 'Aceptar',
                  showCancelButton: false,
                });
                setEndContract({ show: false });
                setEndContractDate('')
                setIsLoading(false);
              } else {
                setIsLoading(false);

                customSwaltAlert({
                  icon: 'warning',
                  title: 'Intenta de nuevo',
                  text: settlementResult.message,
                  confirmButtonText: 'Aceptar',
                  showCancelButton: false,
                });
                setEndContract({ show: false });
                setEndContractDate('')
              }
            } catch (error) {
              setIsLoading(false);

              customSwaltAlert({
                icon: 'warning',
                title: 'Intenta de nuevo',
                text: error.message,
                confirmButtonText: 'Aceptar',
                showCancelButton: false,
              });
            }
          };

          send();
        }
      })
      .catch(err => {
        customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: err.message,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
        setIsLoading(false)
      })
  };

  const isValidDate = (value) => {
    return new Promise((resolve) => {
      if (lastDay >= new Date(value)) {
        resolve(true)
      }
      else {
        resolve(false)
      }
    })
  }

  const Cycles = async (item, value) => {
    dispatch({
      type: GET_PERIODS,
      payload: {},
    });
    const isValid = await isValidDate(value)
    if (!isValid) {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'la fecha de finalización no puede ser mayor al mes actual',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    }
    setInputs({
      ...inputs,
      contract: item?.contract_id,
      employee: item?.cand_id,
      account: item?.account,
      end_date: value,
    });
    dispatch(
      getPeriods({
        contract: item?.contract_id,
        eaccount: counter.loginReducer.currentAccount.id,
        end_date: moment(value).format(strFormat),
      }),
    );

  };

  const opt = {
    Pendiente: 'canceled',
    Firmado: 'canceled',
  };
  const handleChangeContractState = async (type, id, item) => {
    let action = opt[type] || 'finalize';
    try {
      customSwaltAlert({
        icon: 'warning',
        title: '¿Está seguro?',
        text:
          type === 'Pendiente'
            ? `Se anulará el contrato al empleado: ${capitalizeWords(item?.candidate)}`
            : `Se finalizará el contrato al empleado: ${capitalizeWords(item?.candidate)}`,
        confirmButtonText: 'Si, Continuar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
      }).then(result => {
        if (result.isConfirmed) {
          setLoading(true);
          const send = async () => {
            try {
              const result = await updateContractState(
                {
                  action: action,
                  id,
                },
                token,
              );

              if (result.success) {
                setTrigger(trigger + 1);
                return message(
                  'success',
                  'Actualizado exitosamente',
                  `Se ha actualizado el contrato al empleado: ${capitalizeWords(item?.candidate)}`,
                  undefined,
                  true,
                );
              } else {
                return message('warning', 'Intenta de nuevo', result.message, undefined, true);
              }
            } catch (error) {
              message(
                'warning',
                'Intenta de nuevo',
                'Ha ocurrido un error y no se pudo actualizar el estado del contrato',
                undefined,
                true,
              );
            } finally {
              setLoading(false);
            }
          };
          send();
        }
      });
    } catch (error) {
      setLoading(false);
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Ha ocurrido un error y no se pudo actualizar el estado del contrato',
        showCancelButton: false,
      });
    }
  };

  const searchFilters = () => {
    setLoading(true);
    setFilter({
      ...filters,
      page: 1,
    });
    dispatch(getListContract(filters, () => setLoading(false)));
  };

  const handlePageChange = val => {
    if (val !== filters.page) {
      setLoading(true);
    }
    setFilter({
      ...filters,
      page: val,
    });
    getListInvoices(filters);
  };

  let optionJobPos = [{ value: '', label: 'Seleccionar...' }];
  if (Array.isArray(counter.contractReducer.job_positions)) {
    counter.contractReducer.job_positions.forEach(item => {
      optionJobPos.push({
        label: item.job_title,
        value: item.id,
        name: 'job_position',
      });
    });
  }

  const [employeeReplacement, setEmployeeReplacement] = useState(initialEmployeeReplacement);

  const uploadOtroSi = () => {
    let error = '';

    if (!!otrosSiControl.signed_doc === false) {
      error = 'Debe adjuntar un archivo para poder guardar';
    }
    if (!!otrosSiControl.job_position === false) {
      error = 'Debe escoger un cargo';
    }
    if (!!otrosSiControl.base_salary === false) {
      error = 'Debe digitar un salario';
    }
    if (Number(otrosSiControl.base_salary) < otrosSiControl.inmutable_base_salary) {
      error = `El campo 'Nuevo salario' debe ser mayor o igual a ${convertMoneyFormat(otrosSiControl.inmutable_base_salary)}`;
    }

    if (!!otrosSiControl.start_date === false) {
      error = 'Debe asignar una fecha en el campo de vigencia';
    }

    if (error === '') {
      dispatch(
        add_annex_contract(otrosSiControl.contract_id, { ...otrosSiControl, start_date: startDate.format(strFormat) }, () => {
          setOtrosSiControl({
            show: false,
            job_position: '',
            base_salary: 0,
            non_const_income: 0,
            start_date: '',
            end_date: '',
            entity_account: counter.loginReducer.currentAccount.id,
            created_by: counter.loginReducer.user_data.id,
          });
          searchFilters();
        }),
      );
    } else {
      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: error,
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    }
  };

  const handleCloseModalEmployeeRe = () => {
    setEmployeeReplacement(initialEmployeeReplacement);
  };

  const closeModalOtroSi = () => {
    setOtrosSiControl({
      show: false,
      job_position: '',
      base_salary: '',
      non_const_income: 0,
      start_date: '',
      end_date: '',
      entity_account: counter.loginReducer.currentAccount.id,
      created_by: counter.loginReducer.user_data.id,
    });
  };

  const getAccountsContracts = async (search = '', page = 1) => {
    return await dispatch(
      getAccounts({
        active: 1,
        page,
        perpage: 10,
        search,
      }),
    );
  };

  const handleOpenEmployeRe = async (item) => {
    setEmployeeReplacement(state => ({
      ...state,
      loader: true,
    }));
    const accounts = await getAccountsContracts();
    setAccounts(accounts);
    setEmployeeReplacement(state => ({
      ...state,
      loader: false,
      show: true,
      contract_id: item.contract_id,
    }));
  };

  const handleReplacement = () => {
    const contract_info = counter.contractReducer.results.find(x => x.contract_id === employeeReplacement.contract_id)
    // eslint-disable-next-line no-unused-vars
    const { show, loader, contract_id, ...employeeReplacementBody } = employeeReplacement;
    const { star_date, end_date, ...contractObject } = contract_info

    updateEmployeeReplacement({
      url: '/payroll/employee-replacement',
      method: 'POST',
      body: {
        ...employeeReplacementBody,
        "created_by": counter.loginReducer.user_data.id,
        "contract_info": {
          ...contractObject,
          'star_date': convertDateToISOFormat(star_date),
          'end_date': convertDateToISOFormat(end_date)
        }
      },
      token: store.loginReducer.Authorization,
      succesAction: results => {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'success',
          title: results.message,
          confirmButtonText: 'Aceptar',
        }).then(setEmployeeReplacement(initialEmployeeReplacement));
      },
      doAfterException: results => {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: results?.message,
          confirmButtonText: 'Aceptar',
        });
      },
    });
  };

  const handleMarkContinue = ({ contract_id, status }) => {

    postMarkContinue({
      url: '/payroll/contract/expiration-status/',
      method: 'POST',
      body: {
        contract_id,
        status
      },
      token: store.loginReducer.Authorization,
      succesAction: results => {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'success',
          title: results.message,
          confirmButtonText: 'Aceptar',
        }).then((res) => {
          if (res.isConfirmed) {
            hidePopupExtend()
            setTrigger(trigger + 1);
            dispatch(getCheckContractStatus({
              params: {
                account: store.loginReducer.currentAccount.id,
              }
            }));
          }

        });
      },
      doAfterException: results => {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: results?.message,
          confirmButtonText: 'Aceptar',
        });
      },
    });
  };

  const onLoadAccountOptions = async (inputValue, callback) => {
    const accounts = await getAccountsContracts(inputValue);
    setAccounts(accounts);
    callback(
      optionsSelect({
        list: accounts,
        label: 'name',
        value: 'id',
      }),
    );
  };

  const employeeReModal = (
    <ModalNew
      show={employeeReplacement.show}
      onHide={handleCloseModalEmployeeRe}
      title={'Sustitución patronal'}
      btnYesName={'Aceptar'}
      size={'500'}
      btnYesEvent={handleReplacement}
    >
      <div className='d-flex flex-column'>
        <p className={tableStyles.crudModalLabel}>Cuenta</p>
        <AsyncSelect
          cacheOptions
          noOptionsMessage={() => 'No hay datos'}
          styles={customSelectNewDark}
          placeholder={'Seleccionar'}
          defaultOptions={optionsSelect({
            list: accounts,
            label: 'name',
            value: 'id',
          })}
          value={valueSelect({
            list: accounts,
            findId: 'id',
            findLabel: 'name',
            value: employeeReplacement.account,
          })}
          loadOptions={onLoadAccountOptions}
          onChange={e => {
            setEmployeeReplacement({
              ...employeeReplacement,
              account: e.value,
              job_type: undefined,
              site: undefined,
            });
            if (e.value !== '') {
              getJobPosition({
                url: '/payroll/job_position/',
                objFilters: {
                  entity_account: e.value,
                  onlyCurrent: 'current',
                },
                token: store.loginReducer.Authorization,
              });
              getSite({
                url: '/site/',
                objFilters: {
                  id_account: e.value,
                },
                token: store.loginReducer.Authorization,
              });
            }
          }}
        />
      </div>
      <div className='d-flex flex-column'>
        <p className={tableStyles.crudModalLabel}>Cargo</p>
        <SelectComponent
          styles={customSelectNewDark}
          placeholder={'Seleccionar'}
          value={valueSelect({
            list: jobPositionsOptions.results || [],
            findId: 'id',
            findLabel: 'job_title',
            value: employeeReplacement.job_position,
          })}
          onChange={e =>
            setEmployeeReplacement({
              ...employeeReplacement,
              job_position: e.value,
            })
          }
          options={optionsSelect({
            list: jobPositionsOptions.results,
            label: 'job_title',
            value: 'id',
          })}
        />
      </div>

      <div className='d-flex flex-column'>
        <p className={tableStyles.crudModalLabel}>Sede</p>
        <SelectComponent
          styles={customSelectNewDark}
          placeholder={'Seleccionar'}
          value={valueSelect({
            list: siteOptions.data || [],
            findId: 'id',
            findLabel: 'description',
            value: employeeReplacement.site,
          })}
          onChange={e => {
            setEmployeeReplacement({
              ...employeeReplacement,
              site: e.value,
              site_address: e.address,
              site_name: e.label
            });
          }}
          options={optionsSelect({
            list: siteOptions.data,
            label: 'description',
            value: 'id',
          })}
        />
      </div>

      <div className='d-flex flex-column'>
        <p className={tableStyles.crudModalLabel}>Nuevo salario</p>
        <NumberFormat
          className={'register-inputs'}
          value={employeeReplacement.new_salary}
          allowNegative={false}
          min={0}
          style={{ color: '#58595B' }}
          thousandsGroupStyle='thousand'
          thousandSeparator={'.'}
          decimalSeparator={','}
          prefix={'$'}
          onValueChange={values => {
            const { value } = values;
            setEmployeeReplacement({
              ...employeeReplacement,
              new_salary: parseInt(value),
            });
          }}
          placeholder='$0'
        />
      </div>

      <div className='d-flex flex-column'>
        <p className={tableStyles.crudModalLabel}>Ingreso no constitutivo</p>
        <NumberFormat
          className={'register-inputs'}
          value={employeeReplacement.non_cost_income}
          allowNegative={false}
          min={0}
          style={{ color: '#58595B' }}
          thousandsGroupStyle='thousand'
          thousandSeparator={'.'}
          decimalSeparator={','}
          prefix={'$'}
          onValueChange={values => {
            const { value } = values;
            setEmployeeReplacement({
              ...employeeReplacement,
              non_cost_income: parseInt(value),
            });
          }}
          placeholder='$0'
        />
      </div>

      <div className='d-flex flex-column'>
        <p className={tableStyles.crudModalLabel}>Tipo de trabajo</p>
        <SelectComponent
          styles={customSelectNewDark}
          placeholder={'Seleccionar'}
          value={valueSelect({
            list: [
              { id: 'remote', label: 'Remoto' },
              { id: 'on_site', label: 'Presencial' },
            ],
            findId: 'id',
            findLabel: 'label',
            value: employeeReplacement.job_type,
          })}
          onChange={e => {
            setEmployeeReplacement({
              ...employeeReplacement,
              job_type: e.value,
            });
          }}
          options={optionsSelect({
            list: [
              { id: 'remote', label: 'Remoto' },
              { id: 'on_site', label: 'Presencial' },
            ],
            label: 'label',
            value: 'id',
          })}
        />
      </div>

      <div className='d-flex flex-column'>
        <p className={tableStyles.crudModalLabel}>Vigencia desde</p>
        <input
          className={'register-inputs'}
          type='date'
          value={employeeReplacement.valid_from}
          onChange={e =>
            setEmployeeReplacement({
              ...employeeReplacement,
              valid_from: e.target.value
            })
          }
          style={{
            color: '#7FADDE',
            fontWeight: 'bold',
          }}
          min={firtsOfTheMonth()}
        />
      </div>

    </ModalNew>
  );
  const otroSiModal = (
    <ModalNew
      show={otrosSiControl.show}
      onHide={() => closeModalOtroSi()}
      title={'Agregar otro sí'}
      btnYesName={'Subir'}
      size={'500'}
      btnYesEvent={() => uploadOtroSi()}
      btnYesDisabled={counter.contractReducer.otrosi_loading}
    >
      <FileInput
        onChange={file =>
          setOtrosSiControl({
            ...otrosSiControl,
            //...file,
            signed_doc: file.base64,
            filename: file.full_name,

            name_doc: file.name_doc,
            type_doc: file.type_doc,
          })
        }
        name_doc={otrosSiControl.name_doc}
        type_doc={otrosSiControl.type_doc}
      />

      <div className='d-flex flex-column'>
        <p className={tableStyles.crudModalLabel}>Cargo</p>
        <SelectComponent
          styles={customSelectNewDark}
          placeholder={'Seleccionar'}
          value={optionJobPos.find(x => x.value === otrosSiControl.job_position)}
          onChange={e =>
            setOtrosSiControl({
              ...otrosSiControl,
              job_position: e.value,
            })
          }
          options={optionJobPos}
        />
      </div>

      <div className='d-flex flex-column'>
        <p className={tableStyles.crudModalLabel}>Nuevo salario</p>
        <NumberFormat
          className={'register-inputs'}
          value={otrosSiControl.base_salary}
          allowNegative={false}
          min={0}
          style={{ color: '#58595B' }}
          thousandsGroupStyle='thousand'
          thousandSeparator={'.'}
          decimalSeparator={','}
          prefix={'$'}
          onValueChange={values => {
            const { value } = values;
            setOtrosSiControl({
              ...otrosSiControl,
              base_salary: value,
            });
          }}
          placeholder='$0'
        />
      </div>
      <div className='d-flex flex-column'>
        <p className={tableStyles.crudModalLabel}>Ingreso no constitutivo</p>
        <NumberFormat
          className={'register-inputs'}
          value={otrosSiControl.non_const_income}
          allowNegative={false}
          min={0}
          style={{ color: '#58595B' }}
          thousandsGroupStyle='thousand'
          thousandSeparator={'.'}
          decimalSeparator={','}
          prefix={'$'}
          onValueChange={values => {
            const { value } = values;
            setOtrosSiControl({
              ...otrosSiControl,
              non_const_income: value,
            });
          }}
          placeholder='$0'
        />
      </div>

      <div className='d-flex flex-column'>
        <p className={tableStyles.crudModalLabel}>Vigencia desde</p>
        <input
          type='date'
          className={'register-inputs'}
          value={startDate.format(strFormat)}
          min={startDate.format(strFormat)}
          style={{
            color: '#7FADDE',
            fontWeight: 'bold',
          }}
          onChange={e => {
            const selectedDate = moment(e.target.value, strFormat).format(strInlineFormat)

            if (e.target.value && startDate.format(strInlineFormat <= selectedDate)) {
              setOtrosSiControl({
                ...otrosSiControl,
                end_date: '',
                start_date: moment(e.target.value, strFormat).format('DD/MM/YYYY')
              })
            }
          }}
        />
      </div>

      {otrosSiControl.contract_Type === 'Contrato a Término Fijo' && (
        <div className='d-flex flex-column'>
          <p className={tableStyles.crudModalLabel}>Vigencia hasta</p>
          <input
            className={'register-inputs'}
            type='date'
            value={otrosSiControl.end_date}
            onChange={e => {
              const selectedDate = moment(e.target.value, strFormat).format(strInlineFormat)

              if (e.target.value && startDate.format(strInlineFormat) <= selectedDate) {
                setOtrosSiControl({
                  ...otrosSiControl,
                  end_date: e.target.value
                })
              }
            }}
            min={startDate.add('days', 1).format(strFormat)}
            style={{
              color: '#7FADDE',
              fontWeight: 'bold',
            }}
          />
        </div>
      )}
    </ModalNew>
  );
  const [showModal, setShowModal] = useState({
    show: false,
  });
  const [sigPad, setSigPad] = useState({});
  const [file, setFile] = useState({
    filename: '',
    base64: '',
    fileUrl: '',
  });
  const [uploadSw, setUploadSw] = useState(false);
  const handleImagePick = async e => {
    var f = e.target.files[0];
    var ext = f?.name?.split('.')[1];
    if (ext !== 'pdf') {
      return message(
        'warning',
        'Intente de nuevo',
        'El formato del archivo debe ser PDF',
        undefined,
        true,
      );
    } else {
      if (e.target.files[0]) {
        let encode = await convertBase64(e.target.files[0]);
        let file64 = encode.split(',')[1]; // Delete base64 prefix (data:application...
        setFile({
          ...file,
          filename: e.target.files[0].name,
          base64: file64,
          fileUrl: URL.createObjectURL(e.target.files[0]),
        });
        sigPad.off();
      } else {
        sigPad.on();
      }
    }
  };

  const signPad = ref => {
    if (ref?.isEmpty()) {
      setDocSignSw(false);
    } else {
      setDocSignSw(true);
    }
    setSigPad(ref);
  };
  useEffect(() => {
    if (!!file?.filename || !!docSignSw) {
      setUploadSw(false);
    } else {
      setUploadSw(true);
    }
  }, [file, docSignSw]);

  useHasPermissionByTagModule({ module: 'nomina', hasRead: myPermission?.read })

  const header = [
    <th key={1} className='pl-1 col-1 text-start' style={{ paddingLeft: '10px' }}>
      Empleados
    </th>,
    <th key={2} className='text-start'>
      Nombre del cargo
    </th>,
    <th key={3} className='col-1 text-start'>
      Área
    </th>,
    <th key={4} className='col-1 text-center'>
      Fecha inicio
    </th>,
    <th key={5} className='col-1 text-center'>
      Fecha fin
    </th>,
    <th key={6} className='col-1 text-center'>
      Estado
    </th>,
    <th key={7} className='col-1 text-center'></th>,
  ];
  const headerReassign = [
    <th key={1} className='pl-1 col-1 text-start' style={{ paddingLeft: '10px' }}>
      Empleado
    </th>,
    <th key={2} className='text-start'>
      Nombre del cargo
    </th>,
    <th key={6} className='col-1 text-center'>
      Estado
    </th>,
  ];
  const body = [];
  if (Array.isArray(counter.contractReducer.results)) {
    counter.contractReducer.results.forEach(item => {
      body.push(
        <tr>
          <td className='text-start col-2' style={{ paddingLeft: '10px' }}>
            {item.candidate}
          </td>
          <td className='text-start col-2'>{item.position}</td>
          <td className='text-start col-2'>{item.area}</td>
          <td>{item.star_date}</td>
          <td>
            {item.end_date !== '' &&
              item.end_date !== undefined &&
              item.end_date !== null &&
              item.contract_typeid !== 1
              ? item.end_date
              : '-'}
          </td>
          <td className='text-center'>
            <div
              className='rounded-pill p-1'
              style={{
                backgroundColor: item.color,
              }}
            >
              <b style={{ color: item.fontcolor }}>{item.status}</b>
            </div>
          </td>

          <td className='col-1'>
            <CustomPopup
              noHover
              triggerSrc={triggerSrc}
              showEdit={item.status === 'Pendiente' && myPermission?.edit}
              editClickEvent={() => {
                setshowDetail({
                  show: false,
                  selectedContract: item,
                  isEditing: true,
                });
              }}
              showDetails={myPermission?.read}
              textDetails='Ver Detalle'
              showDetailsEvent={() => {
                setshowDetail({
                  show: true,
                  selectedContract: item,
                  isEditing: false,
                });
              }}
              showDelete={
                item.status === 'Finalizado' && !!myPermission?.edit
                  ? false
                  : item.status === 'Anulado' && !!myPermission?.edit
                    ? false
                    : true
              }
              showOtroSi={
                item.status === 'Vigente' &&
                !!myPermission?.edit &&
                item.contract_typename.trim() !== 'Contrato de pasantía' &&
                item.contract_typename.trim() !== 'Contrato de aprendizaje'
              }
              showEmployeeRe={
                item.status === 'Vigente' &&
                !!myPermission?.edit &&
                item.contract_typename.trim() !== 'Contrato de pasantía' &&
                item.contract_typename.trim() !== 'Contrato de aprendizaje' &&
                item.contract_typename.trim() !== 'Contrato por Prestación de Servicios' &&
                item.contract_typename.trim() !== 'Acta entrega de Dotacion'
              }
              employeeReEvent={() => handleOpenEmployeRe(item)}
              deleteEvent={
                item.status === 'Pendiente'
                  ? () => handleChangeContractState(item.status, item.contract_id, item)
                  : item.status === 'Firmado'
                    ? () => handleChangeContractState(item.status, item.contract_id, item)
                    : () => {
                      dispatch({
                        type: GET_PERIODS,
                        payload: {},
                      });
                      setTemp(item);
                      setEndContract({ show: true, data: item });
                      setTriggerSelect(triggerSelect + 1);
                      setTrigger(trigger + 1)
                    }
              }
              otroSiEvent={() => {
                setOtrosSiControl({
                  ...otrosSiControl,
                  show: true,
                  start_date: item.star_date,
                  contract_id: item.contract_id,
                  base_salary: item.base_salary,
                  non_const_income: item.non_const_income,
                  inmutable_base_salary: item.base_salary,
                  contract_Type: item?.contract_typename,
                })
              }
              }
              showSign={item.status === 'Pendiente' && myPermission?.edit}
              signEvent={() =>
                setShowModal({
                  show: true,
                  selectedContract: item.contract_id,
                  candidate: item?.candidate,
                })
              }
              deleteText={
                item.status === 'Pendiente'
                  ? 'Anular'
                  : item.status === 'Firmado'
                    ? 'Anular'
                    : item.status === 'Vigente' &&
                      item.contract_typename.trim() !== 'Contrato de pasantía' &&
                      item.contract_typename.trim() !== 'Contrato de aprendizaje'
                      ? 'Anular'
                      : item.status === 'Vigente' &&
                        (item.contract_typename.trim() === 'Contrato de pasantía' ||
                          item.contract_typename.trim() === 'Contrato de aprendizaje')
                        ? 'Terminación de contrato'
                        : 'Finalizar'
              }
              otroSi='Agregar otro sí'
              extraButtons={[
                {
                  text: <span>Historial otrosí</span>,
                  class: CustomPopupStyles.popUpGeneric,
                  event: item?.contract_rearranged
                    ? () => {
                      history.push({
                        pathname: '/nomina/contratos/historialOtrosi',
                        state: { data: item },
                      });
                    }
                    : null,
                  icon: historyIcon,
                },
                {
                  text: <span>Reasignar personal</span>,
                  class: CustomPopupStyles.popUpGeneric,
                  event: item?.area_leader && item.status === 'Vigente' ? () => onOpenReassignStaff(item) : null,
                  icon: historyIcon,
                },
                {
                  text: <div className={styles.hoverContainer}>
                    <span>¿ Continuará ?</span>
                    <div className={styles.options}>
                      <button
                        onClick={() => handleMarkContinue({ contract_id: item.contract_id, status: 'active' })}
                      >
                        Sí
                      </button>
                      <button
                        onClick={() => handleMarkContinue({ contract_id: item.contract_id, status: 'inactive' })}
                      >
                        No
                      </button>
                    </div>
                  </div>,
                  class: CustomPopupStyles.popUpGeneric,
                  event: item.contract_expiration === 'under_review' && item.status === 'Vigente' ? () => true : null,
                  icon: historyIcon,
                },
              ]}
            />
          </td>
        </tr>,
      );
    });
  }
  const bodyReassign = [];
  if (Array.isArray(staffInChargeResults?.data)) {
    staffInChargeResults?.data?.forEach(item => {
      bodyReassign.push(
        <tr>
          <td className='text-start col-2' style={{ paddingLeft: '10px' }}>
            {item.name}
          </td>
          <td className='text-start col-2'>{item.charge}</td>
          <td className='text-center'>
            <div
              className='rounded-pill p-1'
              style={{
                backgroundColor: item.color,
              }}
            >
              <b style={{ color: item.fontcolor }}>{item.state}</b>
            </div>
          </td>
        </tr>,
      );
    });
  }

  const optionStatus = [{ value: '', label: 'Seleccionar...', name: 'status' }];

  if (Array.isArray(counter.contractReducer.resultsSlectStatus)) {
    counter.contractReducer.resultsSlectStatus.forEach(item => {
      optionStatus.push({
        label: item.value,
        value: item.name,
        name: 'status',
      });
    });
  }

  const optionArea = () => {
    let tempModules = [
      {
        value: '',
        label: 'Seleccionar...',
      },
    ];
    if (Array.isArray(counter.consumptionCenterReducer.consumption_center)) {
      counter.consumptionCenterReducer.consumption_center.forEach(elem => {
        tempModules.push({
          value: elem.id,
          label: elem.description,
        });
      });
    } else {
      tempModules.push({
        value: 'noOptions',
        label: 'No hay datos',
      });
    }
    return tempModules;
  };
  const optionLeadersToReassignResults = () => {
    let tempModules = [
      {
        value: '',
        label: 'Seleccionar...',
      },
    ];
    if (Array.isArray(leadersToReassignResults?.results)) {
      leadersToReassignResults?.results?.forEach((option) =>
        tempModules.push({
          value: option?.userId, label: option?.userName
        }))
    }
    return tempModules;
  };
  const clear = () => {
    setDocSignSw(false);
    setSigPad(sigPad.clear());
  };
  const uploadImage = async () => {
    let id_contract = showModal?.selectedContract;
    if (docSignSw) {
      const imagen = await sigPad.getTrimmedCanvas().toDataURL('image/png');
      let file64 = imagen.split(',')[1];
      const body = {
        type: 2,
        file: {
          base64: file64,
          filename: 'SignatureImage' + id_contract + '.png',
        },
      };
      dispatch(signContract(body, id_contract, () => doAfterSign(), showModal.candidate));
    } else {
      const body = {
        type: 1,
        file: {
          base64: file?.base64,
          filename: file?.filename,
        },
      };
      dispatch(signContract(body, id_contract, () => doAfterSign(), showModal.candidate));
    }
  };

  const doAfterSign = () => {
    setShowModal({ show: false });
    setFilter({
      search: '',
      page: 1,
      perpage: 10,
      status: '',
      account: counter.loginReducer.currentAccount.id,
      area: '',
      position: '',
    });
    setTrigger(trigger + 1);
    clearModalSign();
  };
  const cleanFile = () => {
    setFile({});
    sigPad.on();
  };
  const clearModalSign = () => {
    clear();
    setFile({});
    setShowModal({ show: false });
  };

  const handleSearch = e => {
    e.preventDefault();
    setFilter({ ...filters, page: 1 });
    setTrigger(trigger + 1);
  };

  const onOpenReassignStaff = (item) => {
    getleadersToReassign({
      url: '/payroll/user-reassignment/',
      objFilters: {
        eaccount: counter.loginReducer.currentAccount.id,
        contractStatus: 'enabled',
        contractId: item?.contract_id,
      },
      token: store.loginReducer.Authorization,
      doAfterExceptionResults: true,
    });
    postStaffInCharge({
      url: '/payroll/user-reassignment/',
      method: 'POST',
      body: {
        "contract": item?.contract_id,
        "account": counter.loginReducer.currentAccount.id
      },
      token: store.loginReducer.Authorization,
      succesAction: (res) => {
        setShowReassignStaff((states) => ({
          ...states,
          show: true,
          item,
          usersToReassign: res?.data?.map((obj) => obj?.id) ?? []
        }))
      },
      doAfterException: results => {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: results?.message,
          confirmButtonText: 'Aceptar',
        });
      },
    });
  };

  const onCloseReassignStaff = () => {
    setShowReassignStaff(initialReassignStaff)
  };

  const onPeassignStaffInCharge = () => {
    const { superiorId, usersToReassign } = showReassignStaff
    if (!superiorId) {
      return customSwaltAlert({
        showCancelButton: false,
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Es necesario seleccionar a una persona encargada para llevar a cabo la reasignación del personal.',
        confirmButtonText: 'Aceptar',
      });
    }
    if (!usersToReassign?.length) {
      return customSwaltAlert({
        showCancelButton: false,
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'El contrato seleccionado no tiene personal disponible para reasignar.',
        confirmButtonText: 'Aceptar',
      });
    }
    putReassignStaffInCharge({
      url: '/payroll/user-reassignment/',
      method: 'PUT',
      body: {
        superiorId,
        usersToReassign
      },
      token: store.loginReducer.Authorization,
      succesAction: (res) => {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'success',
          title: res.message,
          confirmButtonText: 'Aceptar',
        }).then((res) => {
          if (res.isConfirmed) {
            onCloseReassignStaff()
          }
        });
      },
      doAfterException: results => {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: results?.message,
          confirmButtonText: 'Aceptar',
        });
      },
    });
  }

  let buttonsSlideDown = [
    {
      button: (
        <img
          alt='btn'
          style={{
            transform: !rotate && 'rotate(180deg)',
          }}
          width={10}
          src={downGrey}
        ></img>
      ),
    },
  ];

  const renderPrincipal = (
    <>
      {(counter.contractReducer.loading ||
        getValidationLoader ||
        employeeReplacement.loader ||
        jobPositionsLoader ||
        siteLoader ||
        employeeReplacementLoader ||
        isLoading ||
        leadersToReassignLoader ||
        staffInChargeLoader ||
        reassignStaffInChargeLoader ||
        markContinueLoader ||
        counter.contractReducer.otrosi_loading) &&
        loader}
      <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: '3rem' }}>
        <div className='d-flex'>
          <h1 className={tableStyles.title}>Contratos</h1>
        </div>
        <ModalNew
          show={showReassignStaff.show}
          onHide={() => onCloseReassignStaff()}
          title={'Reasignar personal'}
          btnYesEvent={() => onPeassignStaffInCharge()}
          btnYesName={'Reasignar'}
          size={'750'}
        >
          <Row>
            <Col>
              <p className={tableStyles.crudModalLabel}>Lideres de area</p>
              <SelectComponent
                value={optionLeadersToReassignResults().find(x => x.value === showReassignStaff.superiorId)}
                styles={customSelectNewDark}
                placeholder={'seleccionar...'}
                key={'superior' + trigger}
                onChange={e => {
                  setShowReassignStaff((states) => ({
                    ...states,
                    superiorId: e.value,
                  }));
                }}
                options={optionLeadersToReassignResults()}
              ></SelectComponent>
            </Col>
          </Row>
          <div style={{
            overflowY: 'auto',
            maxHeight: '400px',
            marginTop: 5,
            zIndex: 0,
            position: 'relative'
          }}>
            <GenericTable headers={headerReassign} dark={true} styleHead={{
              position: 'sticky',
              top: 0,
              zIndex: 10
            }}>
              {bodyReassign}
            </GenericTable>
          </div>
        </ModalNew>
        {!!myPermission?.read && (
          <OrdSlideDownButton
            onToggleClick={() => setRotate(!rotate)}
            buttons={buttonsSlideDown}
            accordionClassName={tableStyles.OrdSlideButtonNom}
          >
            <Row>
              <Col xs={2}>
                <p className={tableStyles.crudModalLabel}>Área</p>
                <SelectComponent
                  value={optionArea().filter(x => x.value === filters.area)}
                  styles={customSelectNewDark}
                  placeholder={'seleccionar...'}
                  key={'area' + trigger}
                  onChange={e => {
                    setLoading(true);
                    setFilter({
                      ...filters,
                      area: e.value,
                      page: 1,
                    });
                    dispatch(
                      getListContract({ ...filters, area: e.value, page: 1 }, () =>
                        setLoading(false),
                      ),
                    );
                    //  searchFilters(e);
                  }}
                  options={optionArea()}
                ></SelectComponent>
              </Col>
              <Col xs={2}>
                <p className={tableStyles.crudModalLabel}>Estado</p>
                <SelectComponent
                  value={optionStatus.filter(x => x.value === filters.status)}
                  styles={customSelectNewDark}
                  placeholder={'Seleccionar...'}
                  key={'state' + trigger}
                  onChange={e => {
                    setLoading(true);
                    setFilter({
                      ...filters,
                      status: e.value,
                      page: 1,
                    });
                    dispatch(
                      getListContract({ ...filters, status: e.value, page: 1 }, () =>
                        setLoading(false),
                      ),
                    );
                  }}
                  options={optionStatus}
                ></SelectComponent>{' '}
              </Col>
              <Col xs={2}>
                <p className={tableStyles.crudModalLabel}>Tipo de contrato</p>
                <SelectComponent
                  styles={customSelectNewDark}
                  placeholder={'Seleccionar...'}
                  options={contractTypes}
                  key={'state' + trigger}
                  onChange={e => {
                    setLoading(true);
                    setFilter({
                      ...filters,
                      contract_type: e.value,
                      page: 1,
                    });
                    dispatch(
                      getListContract({ ...filters, contract_type: e.value, page: 1 }, () =>
                        setLoading(false),
                      ),
                    );
                  }}
                  value={contractTypes?.filter(x => x.value === filters.contract_type)}
                ></SelectComponent>{' '}
              </Col>
              <Col xs={3}>
                <p className={`${tableStyles.crudModalLabel} `}>Fecha desde</p>
                <input
                  placeholder='Escribe...'
                  className='register-inputs text-primary  fw-bold'
                  type='date'
                  onChange={e => setFilter({ ...filters, dateFrom: e.target.value })}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      handleSearch(e);
                    }
                  }}
                />
              </Col>
              <Col xs={3}>
                <p className={`${tableStyles.crudModalLabel} `}>Fecha hasta</p>
                <input
                  placeholder='Escribe...'
                  className='register-inputs text-primary  fw-bold'
                  type='date'
                  onChange={e => setFilter({ ...filters, dateUntil: e.target.value })}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      handleSearch(e);
                    }
                  }}
                />
              </Col>
            </Row>
            <Row className='mt-4'>
              <Col xs={6} className='d-flex align-self-end justify-content-end gap-4'>
                <input
                  className={IndividualStyles.Search}
                  value={filters.search}
                  placeholder='Buscar...'
                  type='text'
                  onChange={e =>
                    setFilter({
                      ...filters,
                      search: e.target.value,
                    })
                  }
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      searchFilters(e);
                    }
                  }}
                ></input>
                <img
                  className='hoverPointer'
                  alt='iconSearch'
                  style={{}}
                  src={Lupa}
                  height='24px'
                  onClick={e => searchFilters(e)}
                />
              </Col>
            </Row>
          </OrdSlideDownButton>
        )
        }
        {
          myPermission?.create ? (
            <Col xs={8} className='align-self-end'>
              <div className='d-flex justify-content-end'>
                <div onClick={handleClick} className={tableStyles.createNomModule}>
                  <b className={`mr-2`}>Crear contrato</b>
                  <img src={Agregar} alt='User' />
                  <div></div>
                </div>
              </div>
            </Col>
          ) : null
        }
        <div>
          <ModalNew
            show={showModal.show}
            onHide={() => clearModalSign()}
            title={'Firma del contrato'}
            btnYesEvent={() => uploadImage()}
            btnYesDisabled={!!uploadSw}
            btnYesName={'Subir'}
            size={'750'}
          >
            <Row>
              <div className='col-5'>
                <label className={tableStyles.crudModalLabel}>Documento firmado</label>
                {file.filename ? (
                  <img
                    src={x}
                    style={{
                      marginLeft: '5px',
                      zIndex: 3000,
                      position: 'absolute',
                      top: '112px',
                      left: '276px',
                      cursor: 'pointer',
                    }}
                    onClick={e => cleanFile(e)}
                    alt='damnit'
                  />
                ) : (
                  ''
                )}
                <label
                  className={`${docSignSw ? Styles.registerInputsDisabled : Styles.registerInputsClear} hoverPointer d-flex`}
                  htmlFor='cvUpload'
                  disabled={docSignSw}
                >
                  {file.filename ? <img src={PdfIcon} alt='' srcSet='' htmlFor='cvUpload' /> : ''}
                  <span
                    className={`text-truncate ${tableStyles.crudModalLabel}`}
                    style={{
                      marginTop: '0.2rem',
                      width: '140px',
                      fontWeight: 'bold',
                    }}
                  >
                    {!file.filename ? 'Inserte PDF' : file.filename}
                  </span>
                  {!file.filename ? (
                    <img
                      style={{
                        marginLeft: 'auto',
                        width: '16px',
                        alignSelf: 'self-end',
                        marginBottom: '8px',
                      }}
                      src={adj}
                      alt=''
                    />
                  ) : (
                    ''
                  )}
                  <input
                    className={Styles.imageInputBtn}
                    type='file'
                    accept='application/pdf'
                    name=''
                    id='cvUpload'
                    disabled={docSignSw}
                    onChange={handleImagePick}
                  />
                </label>
              </div>
              <div className='col-1' style={{ alignSelf: 'self-end' }}>
                <img src={guion} style={{ height: '168px' }} alt='' />
              </div>
              <div className='col-6'>
                <label className={tableStyles.crudModalLabel}>Firma digital</label>
                {docSignSw ? (
                  <img
                    src={x}
                    style={{
                      marginLeft: '5px',
                      position: 'absolute',
                      top: '112px',
                      zIndex: 30000,
                      right: '50px',
                      cursor: 'pointer',
                    }}
                    onClick={() => clear()}
                    alt=''
                  />
                ) : (
                  ''
                )}
                <div
                  className={`${Styles.sigContainer} ${!file?.filename ? Styles.sigPad : Styles.sigPadDisabled}`}
                >
                  <SignaturePad
                    ref={ref => signPad(ref)}
                    canvasProps={{ width: 311, height: 167 }}
                  />
                </div>
              </div>
            </Row>
          </ModalNew>
        </div>
        {
          !!myPermission?.read && (
            <>
              <GenericTable headers={header} dark={true}>
                {body}
              </GenericTable>

              <div className={paginationStyles.wrapper}>
                <p className={` ${paginationStyles.paginationText} darkGray`}>
                  pag. {counter.contractReducer.total ? filters.page : '1'}
                  {' de '}
                  {Math.ceil(counter.contractReducer.total / filters.perpage)
                    ? Math.ceil(counter.contractReducer.total / filters.perpage)
                    : '1'}{' '}
                  ({' ' + counter.contractReducer.total}
                  {' encontrados'})
                </p>
                <Pagination
                  activePage={filters.page}
                  itemsCountPerPage={filters.perpage}
                  totalItemsCount={counter.contractReducer.total}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                  itemClassPrev={`${paginationStyles.itemClassPrev} `}
                  itemClassNext={`${paginationStyles.itemClassNext} `}
                  itemClassFirst={`${paginationStyles.itemClassFirst} `}
                  itemClassLast={`${paginationStyles.itemClassLast} `}
                  itemClass={paginationStyles.itemClass}
                />
              </div>
            </>
          )
        }
      </div >
      {employeeReModal}
      {otroSiModal}
    </>
  );
  return showDetail.show ? (
    <CreateContract
      information={showDetail.selectedContract}
      show={showDetail.show}
      isEditing={showDetail.isEditing}
      setshowDetail={setshowDetail}
      setFilters={setFilter}
    />
  ) : showDetail.isEditing ? (
    <CreateContract
      information={showDetail.selectedContract}
      show={showDetail.show}
      isEditing={showDetail.isEditing}
      setshowDetail={setshowDetail}
      setFilters={setFilter}
      cleanDetails={() =>
        setshowDetail({
          show: false,
          isEditing: false,
        })
      }
    />
  ) : (
    <>
      {(isLoading || loading) && (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      )}

      <ModalNew
        show={endContract.show}
        onHide={() => {
          setEndContract(state => ({
            ...state,
            data: { ...state.data, candidate: '', star_date: '' },
            show: false,
          }));

          setEndContractDate('');

          setInputs(state => ({
            ...state,
            last_severance_pay: '',
            last_salary_bonus: '',
            last_severance_interest: '',
            reason: '',
          }));
        }}
        title={'Finalización de contrato'}
        btnYesName={'Aceptar'}
        btnYesEvent={() => getValidation()}
        subtitle={`Tipo de contrato: ${endContract?.data?.contract_typename}`}
        size={'500'}
      >
        <Row>
          <Col xs={6}>
            <p className={`${tableStyles.crudModalLabel} `}>Empleado</p>
            <input
              disabled={true}
              value={endContract?.data?.candidate}
              className='register-inputs text-secondary'
              name='position'
            />
          </Col>
          <Col xs={6}>
            <p className={`${tableStyles.crudModalLabel} `}>Fecha de inicio</p>
            <input
              disabled={true}
              value={endContract?.data?.star_date}
              className='register-inputs text-secondary'
              name='position'
            />
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <p className={`${tableStyles.crudModalLabel} `}>Fecha de finalización</p>
            <input
              disabled={
                ['apprenticeship_contract', 'internship_contract'].includes(endContract?.data?.contract_type)
              }
              value={endContractDate}
              type='date'
              max={moment(lastDay).format('YYYY-MM-DD')}
              className='register-inputs text-secondary'
              name='position'
              onChange={e => {
                Cycles(temp, e.target.value);
                setEndContractDate(e.target.value);
                setInputs(states => ({
                  ...states,
                  end_date: e.target.value
                }))
              }}
            />
          </Col>

          {endContract?.data?.status === 'Vigente' &&
            (endContract?.data?.contract_typename.trim() === 'Contrato de pasantía' ||
              endContract?.data?.contract_typename.trim() === 'Contrato de aprendizaje' ? null : (
              <>
                {/* THIRD */}
                {periods?.last_severence?.length > 0 && (
                  <Col xs={6}>
                    <p className={`${tableStyles.crudModalLabel} `}>Último periodo de cesantías</p>
                    <SelectComponent
                      onChange={e => setInputs({ ...inputs, last_severance_pay: e.extra })}
                      options={formattedLayoffs}
                      className='text-secondary'
                      placeholder={'Seleccionar...'}
                      styles={customSelectNew}
                      value={formattedLayoffs.find(e => e.extra === inputs.last_severance_pay)}
                    ></SelectComponent>
                  </Col>
                )}

                {/* FOURTH */}
                {periods?.last_bonus_salary?.length > 0 && (
                  <Col xs={6}>
                    <p className={`${tableStyles.crudModalLabel} `}>Último periodo de primas</p>
                    <SelectComponent
                      key={triggerSelect + 'select'}
                      onChange={e => {
                        let value = e.extra2 === '0' ? '0' : e.extra + '-' + e.extra2;
                        setInputs({
                          ...inputs,
                          last_salary_bonus: value,
                        });
                      }}
                      options={bonusSalaryOptions}
                      className='text-secondary'
                      placeholder={'Seleccionar...'}
                      styles={customSelectNew}
                      value={bonusSalaryOptions.find(e =>
                        e.extra2 === '0'
                          ? '0'
                          : e.extra + '-' + e.extra2 === inputs.last_salary_bonus,
                      )}
                    ></SelectComponent>
                  </Col>
                )}

                {/* FIFTH */}
                {periods?.last_severance_interest?.length > 0 && (
                  <Col xs={6}>
                    <p className={`${tableStyles.crudModalLabel} `}>Último periodo de intereses</p>
                    <SelectComponent
                      onChange={e =>
                        setInputs({
                          ...inputs,
                          last_severance_interest: e.extra,
                        })
                      }
                      options={formattedSeveranceInterest}
                      className='text-secondary'
                      placeholder={'Seleccionar...'}
                      styles={customSelectNew}
                      value={formattedSeveranceInterest.find(
                        e => e.extra === inputs.last_severance_interest,
                      )}
                    ></SelectComponent>
                  </Col>
                )}

                {/* SIXTH */}
                <Col xs={12} className='mt-2'>
                  <h6 className={`${tableStyles.darkBlueText} text-center`}>
                    <b>Motivo</b>
                  </h6>
                  <hr></hr>
                  <SelectComponent
                    onChange={e => setInputs({ ...inputs, reason: e.value })}
                    options={formattedReasons}
                    className='text-secondary'
                    placeholder={'Seleccionar...'}
                    styles={customSelectNew}
                    value={formattedReasons.find(e => e.value === inputs.reason)}
                  ></SelectComponent>
                </Col>
              </>
            ))}
        </Row>
      </ModalNew>
      {renderPrincipal}
    </>
  );
}
export default ListContracts;
