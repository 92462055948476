import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import Search from '../../../assets/img/icons/lupa.svg';
import { useGetMethod } from '../../../Hooks/useFetch';
import GenericTableNew from '../../Layouts/GenericTableNew';
import SelectComponent from '../../SelectComponent/SelectComponent';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import 'reactjs-popup/dist/index.css';
import { customSelectNewDark } from '../../Layouts/react-select-custom';
import tableStyles from '../../Layouts/tableStyle.module.scss';
import '../../TableUsers/tableUserStyle.scss';
import { useHasPermissionByTagModule } from '../../../Hooks';
import { convertMoneyFormat } from '../../../helpers';
export const DiscountsAndCreditCards = () => {
  const store = useSelector(state => state);
  const myPermission = store.loginReducer.currentAccount?.profile?.permission?.find(
    x => x.functionality?.prefix === 'pplDiscounts',
  );
  const idAccount = store.loginReducer.currentAccount.id;
  const token = store.loginReducer.Authorization;

  const {
    load: loaderDiscountsAndCreditCards,
    trigger: getDiscountsAndCreditCards,
    results: resultsDiscountsAndCreditCards
  } = useGetMethod();

  const {
    load: loaderThird,
    trigger: getAdjustments,
    results: resultsAdjustments
  } = useGetMethod();

  const [filters, setFilters] = useState({
    search: '',
    entity_account: idAccount,
    third: '',
  });

  const [trigger, setTrigger] = useState(0);

  useEffect(() => {
    getDiscountsAndCreditCards({
      url: '/payroll/discounts/',
      token: token,
      objFilters: filters,
      doAfterExceptionResults: true
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDiscountsAndCreditCards, token, trigger]);

  useEffect(() => {
    getAdjustments({
      url: '/payroll/adjustments/Type/',
      token: token,
      objFilters: { entity_account: idAccount, status: 'enabled', type: 1 },
      doAfterExceptionResults: true
    });
  }, [getAdjustments, token, idAccount]);

  useHasPermissionByTagModule({ module: 'nomina', hasRead: myPermission?.read });

  const optionsAdjustments = [
    {
      value: '',
      label: 'Seleccionar...',
    },
  ];

  if (Array.isArray(resultsAdjustments?.result)) {
    resultsAdjustments?.result?.forEach(elem => {
      optionsAdjustments.push({
        value: elem.adjustments_id,
        label: elem.description,
      });
    });
  }

  const renderElement = elem => {
    return (
      <tr key={elem.id}>
        <td className='col-3 px-3 text-start'>{elem?.candidate_name || ''}</td>

        <td className='col-1 text-center'>{elem.start_date || ''}</td>

        <td className='col-1 text-start'>{elem?.type || ''}</td>

        <td className='col-1 text-start'>{elem?.third || ''}</td>

        <td
          className='col-1 text-center'
        >
          {convertMoneyFormat(elem?.total || 0)}
        </td>

        <td className='col-1 text-center'>
          {convertMoneyFormat(elem?.fee_value || 0)}
        </td>

        <td className='col-1 text-center'>
          {elem?.count || ''}
        </td>

        <td className='col-1 text-center'>
          {convertMoneyFormat(elem?.balance_date || 0)}
        </td>
      </tr>
    );
  };

  const renderElementTotal = (total) => {
    return (
      <tr>
        <td className='col-3 px-3 text-start'></td>
        <td className='col-1 text-center'></td>
        <td className='col-1 text-start'></td>

        <td className='col-1 text-end'
          style={{
            textTransform: "uppercase",
            fontFamily: "PT Sans",
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: "0.825rem",
            lineHeight: "21px",
            color: "#58595B",
          }}
        >
          TOTAL
        </td>

        <td
          className='col-1 text-center'
        >
          {convertMoneyFormat(total || 0)}
        </td>

        <td className='col-1 text-center'></td>
        <td className='col-1 text-center'></td>
        <td className='col-1 text-center'></td>
      </tr >
    );
  };

  const listElem = () => {
    let elemMap = [];
    const data = resultsDiscountsAndCreditCards?.result?.current
    if (Array.isArray(data)) {
      elemMap = data.map(elem => {
        return renderElement(elem);
      });
    }
    return elemMap;
  };

  const renderHeaders = [
    <th key={1} className='text-start col-3 px-3'>
      Nombre completo
    </th>,

    <th key={2} className='text-center col-1'>
      Fecha de inicio
    </th>,

    <th key={3} className='text-start col-1'>
      Tipo
    </th>,

    <th key={4} className='text-start col-1'>
      Terceros
    </th>,

    <th key={5} className='text-center col-1'>
      Total adeudado
    </th>,

    <th key={6} className='text-center col-1'>
      Valor cuota
    </th>,

    <th key={7} className='text-center col-1'>
      Cantidad
    </th>,

    <th key={8} className='text-center col-1'>
      Saldo a la fecha
    </th>,
  ];

  const handleSearch = e => {
    e.preventDefault();
    setTrigger(trigger + 1);
  };

  return (
    <>
      <div className={tableStyles.container}>
        {(loaderDiscountsAndCreditCards || loaderThird) && (
          <div className='loading'>
            <Loader type='Oval' color='#003f80' height={100} width={100} />
          </div>
        )}

        <div className={tableStyles.tableArea}>
          <Row>
            <Col xs={10}>
              <div className={tableStyles.title}>
                <h1>Descuentos y libranzas</h1>
              </div>
            </Col>
          </Row>
          {!!myPermission?.read && (
            <>
              <Row>
                <Col xs={2}>
                  <p className={tableStyles.crudModalLabel}>Terceros</p>
                  <SelectComponent
                    styles={customSelectNewDark}
                    placeholder={'Seleccionar...'}
                    onChange={e => {
                      setFilters({ ...filters, third: e.value });
                      setTrigger(trigger + 1);
                    }}
                    options={optionsAdjustments}
                    value={optionsAdjustments.find(x => x.value === filters.third)}
                  ></SelectComponent>
                </Col>
                <Col xs={4} className='d-flex align-self-end gap-1'>
                  <input
                    className={tableStyles.SearchNew}
                    value={filters.search}
                    placeholder='Buscar...'
                    type='text'
                    onChange={e => setFilters({ ...filters, search: e.target.value })}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        handleSearch(e);
                      }
                    }}
                  ></input>
                  <img
                    className='hoverPointer'
                    alt='iconSearch'
                    src={Search}
                    height='24px'
                    onClick={e => handleSearch(e)}
                  />
                </Col>
              </Row>
              <div style={{
                overflowY: 'auto',
                maxHeight: '510px',
                zIndex: 0,
                position: 'relative',
                marginTop: '1rem'
              }}>
                <GenericTableNew
                  headers={renderHeaders}
                  dark={true}
                  style={{ marginTop: 0 }}
                  styleHead={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 1,
                  }}
                  styleFooter={{
                    position: 'sticky',
                    bottom: 0,
                    zIndex: 1,
                    borderRadius: 0
                  }}
                  footer={
                    listElem().length > 0 ?
                      renderElementTotal(resultsDiscountsAndCreditCards?.result?.totals)
                      : null
                  }
                >
                  {
                    listElem().length > 0 ?
                      listElem()
                      : null
                  }
                </GenericTableNew>
              </div>

            </>
          )}
        </div>
      </div>
    </>
  );
};
