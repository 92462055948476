import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Pagination from 'react-js-pagination';
import Loader from 'react-loader-spinner';

import Lupa from '../../assets/img/icons/lupa.svg';
import threeDots from '../../assets/img/icons/threeDots.svg';
import iconProvisions from '../../assets/img/icons/cloneIcon.svg';

import { URL_GATEWAY, API_VERSION } from '../../helpers/constants';
import { convertFilterToString } from '../../helpers/convertToFilter';
import { customSwaltAlert } from '../../helpers/customSwaltAlert';
import { useGetMethod } from '../../Hooks/useFetch';
import { convertMoneyFormat, message } from '../../helpers/helpers';

import GenericTable from '../../components/Layouts/GenericTableNew';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import paginationStyles from '../../components/Layouts/pagination.module.scss';
import CustomPopupStyles from '../../components/Popup/customPopup.module.scss';
import CustomPopupExtend from '../../components/Popup/customPopUpExtends';
import ModalNew from '../../components/Layouts/ModalNew';
import DetailPayrollTemplate from './DetailPayrollTemplate';
import SelectComponent from '../SelectComponent/SelectComponent';

import { customSelectNewDark } from '../../components/Layouts/react-select-custom';
import Styles from './generatePayroll.module.scss';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { useHasPermissionByTagModule } from "../../Hooks";
import { ImbalanceWarningModal } from './ImbalanceWarningModal';

const monthNames = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

function getColorStatus({ color, fontColor, textStatus, status_label }) {
  switch (textStatus) {
    case textStatus:
      return {
        status_es: status_label,
        color,
        fontColor,
      };

    default:
      return {
        status_es: status_label,
        color,
        fontColor,
      };
  }
}

function GeneratePayrollList() {
  let history = useHistory();
  const counter = useSelector(state => state);

  const myPermission = counter.loginReducer.currentAccount?.profile?.permission?.find(
    x => x.functionality?.prefix === 'plandenom',
  );
  const token = counter.loginReducer.Authorization;
  const [showImbalanceModal, setShowImbalanceModal] = useState(false)

  const [trigger, setTrigger] = useState(1);
  const [filters, setFilter] = useState({
    page: 1,
    perpage: 10,
    month: undefined,
    entity_account: counter.loginReducer.currentAccount.id,
    module: 'payrollGen',
  });
  const [details, setDetails] = useState({
    show: false,
    sheet: '',
    status: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [nominaList, setNominaList] = useState([]);
  const [nominaTotal, setNominaTotal] = useState([]);
  const [showModal, setShowModal] = useState({
    show: false,
  });
  const [generate, setGenerate] = useState({
    entity_account: counter.loginReducer.currentAccount.id,
    entity_name: counter.loginReducer.currentAccount.name,
    start_date: '',
    end_date: '',
    payment_date: '',
    created_by: counter.loginReducer.user_data.id,
  });

  useHasPermissionByTagModule({ module: 'nomina', hasRead: myPermission?.read })
  const { trigger: getImbalance, results: resultImbalace, load: loadImbalance } = useGetMethod()

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      let str_filters = convertFilterToString(filters);
      await fetch(`${URL_GATEWAY}${API_VERSION}/payroll/payroll_spreadsheets/?${str_filters}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: token,
        },
      })
        .then(response => response.json())
        .then(res => {
          setNominaList(res.results);
          setNominaTotal(res.rowTotal);
          setIsLoading(false);
        })
        .catch(err => {
          console.error(err.message);
          setIsLoading(false);
        });
    }
    fetchData();
  }, [filters, filters.page, token, trigger]);

  const editDataStep2 = async (data, method) => {
    setIsLoading(true);
    await fetch(`${URL_GATEWAY}${API_VERSION}/payroll/payroll_sheet/`, {
      method: method,
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
      .then(response => response.json())
      .then(res => {
        if (res.success) {
          customSwaltAlert({
            icon: 'success',
            title: 'Enviada exitosamente',
            text: res.message,
            showCancelButton: false,
            confirmButtonText: 'Aceptar',
          });
          setTrigger(trigger + 1);
        } else {
          customSwaltAlert({
            icon: 'warning',
            title: 'Intenta de nuevo',
            text: res.message,
            showCancelButton: false,
            confirmButtonText: 'Aceptar',
          });
        }
        setIsLoading(false);
      })
      .catch(() => {
        customSwaltAlert({
          icon: 'error',
          title: '¡Ups! Algo salió mal',
          text: `Ocurrió un error al enviar la plantilla de nómina para el periodo ${data.see_month} ${data.see_year}. Por favor, inténtalo nuevamente en unos minutos. Si el problema persiste, contacta al administrador del sistema.`,
          showCancelButton: false,
          confirmButtonText: 'Aceptar',
        });
        setIsLoading(false);
      });
  };

  const editData = async data => {
    let message = `Se enviará la plantilla de nómina periodo ${data.see_month} ${data.see_year}`;
    if (data.status === 'cancelled') {
      message = `Se eliminará la plantilla de nómina periodo ${data.see_month} ${data.see_year}`;
    }
    customSwaltAlert({
      icon: 'warning',
      title: '¿Está seguro?',
      showCancelButton: true,
      text: message,
      confirmButtonColor: '#005DBF',
      cancelButtonColor: '#fff',
      confirmButtonText: 'Sí, continuar',
      cancelButtonText: 'Cancelar',
    }).then(response => {
      if (response.isConfirmed) {
        editDataStep2(data, data.status === 'cancelled' ? 'DELETE' : 'PUT');
      }
    });
  };

  const { load: statusPayrollLoader, trigger: getStatusPayroll } = useGetMethod();

  const handleCloseImbalanceModal = () => {
    setShowImbalanceModal(false)
  }

  const isThereImbalance = (payrollId) => {
    return new Promise((resolve) => {
      getImbalance({
        url: '/payroll/verify_data',
        objFilters: {
          entity_account: counter.loginReducer.currentAccount.id,
          payroll_id: payrollId
        },
        token: token,
        succesAction: res => {
          if (res.success) {
            if (res.results.length !== 0) {
              setShowImbalanceModal(true)
              resolve(true)
            }
          }
          resolve(false)
        }
      })
    })
  }

  const btnGenerate = async data => {
    setIsLoading2(true);
    await fetch(`${URL_GATEWAY}${API_VERSION}/payroll/generate-payroll-template/`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
      .then(response => response.json())
      .then(res => {
        if (res.success) {
          customSwaltAlert({
            icon: 'success',
            title: 'Actualizado exitosamente',
            text: `${res.message}`,
            confirmButtonText: 'Aceptar',
            showCancelButton: false,
          });
          setTrigger(trigger + 1);
          setShowModal({ show: false });
          setIsLoading2(false);
          setGenerate({
            entity_account: counter.loginReducer.currentAccount.id,
            entity_name: counter.loginReducer.currentAccount.name,
            start_date: '',
            end_date: '',
            payment_date: '',
            created_by: counter.loginReducer.user_data.id,
          });
          getStatusPayroll({
            url: '/payroll/payroll_spreadsheets/',
            objFilters: {
              entity_account: counter.loginReducer.currentAccount.id,
              payrollSheetId: res.payroll_sheet,
            },
            token: token,
          });
        } else {
          setIsLoading2(false);
          customSwaltAlert({
            icon: 'warning',
            title: 'Intenta de nuevo',
            text: `${res.message}`,
            confirmButtonText: 'Aceptar',
            showCancelButton: false,
          });
        }
      })
      .catch(err => {
        console.error(err.message);
        setIsLoading2(false);
      });
  };

  const searchFilters = () => {
    setFilter({
      ...filters,
      page: 1,
    });
    setTrigger(trigger + 1);
  };

  const handlePageChange = val => {
    setFilter({
      ...filters,
      page: val,
    });
  }
  
  const sendToAccounting = async data => {
    try {
      setIsLoading(true);
      const url = `${URL_GATEWAY}${API_VERSION}/payroll/changeStatus_payrollSheet/${data.id}/`;
      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      };
      const response = await fetch(url, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: headers,
      });
      const result = await response.json();
  
      if (result.success) {
        setIsLoading(false);
        customSwaltAlert({
          icon: 'success',
          title: 'Enviada exitosamente',
          text: result.message,
          showCancelButton: false,
          confirmButtonText: 'Aceptar',
        });
        setTrigger(trigger + 1);
      } else {
        setIsLoading(false);
        customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: result.message,
          showCancelButton: false,
          confirmButtonText: 'Aceptar',
        });
      }
    } catch (error) {
      setIsLoading(false);
      customSwaltAlert({
        icon: 'error',
        title: '¡Ups! Algo salió mal',
        text: 'Ha ocurrido un error al enviar la planilla a contabilidad',
        showCancelButton: false,
        confirmButtonText: 'Aceptar',
      });
    }
  };
  
  const sendToAccountingValidation = async data => {
    customSwaltAlert({
      icon: 'warning',
      title: '¿Está seguro?',
      showCancelButton: true,
      text: `Se enviará la plantilla de nómina año ${data.see_year}, periodo ${data.see_month}`,
      confirmButtonColor: '#005DBF',
      cancelButtonColor: '#fff',
      confirmButtonText: 'Sí, continuar',
      cancelButtonText: 'Cancelar',
    }).then(response => {
      if (response.isConfirmed) {
        sendToAccounting(data);
      }
    });
  };
  

  const handleEdit = async (item, textMonth) => {
    const imbalance = await isThereImbalance(item.PS_ID);
    if (imbalance) {
      return;
    }
    sendToAccountingValidation({
      id: item.PS_ID,
      see_year: item.payment_year,
      see_month: textMonth,
      status:
        item?.status === 'loaded' || item?.status === 'rejected'
          ? 'review'
          : 'settlement',
    });
  };
  const header = [
    <th key={1} className='pl-1 col-2 text-start' style={{ paddingLeft: '10px' }}>
      Descripción
    </th>,
    <th key={2} className='pl-1 col-2 text-start'>
      Año
    </th>,
    <th key={3} className='pl-1 col-2 text-start'>
      Mes
    </th>,
    <th key={4} className='col-1 text-center'>
      Periodo
    </th>,
    <th key={5} className='col-2 text-center'>
      No. de empleados
    </th>,
    <th key={6} className='col-2 text-center'>
      <p style={{ margin: 0, paddingRight: '15px', textAlign: 'end' }}>Total</p>
    </th>,
    <th key={7} className='col-1 text-center'>
      Estado
    </th>,
    <th key={8} className='col-1 text-center' style={{ paddingLeft: '10px' }}>
      &nbsp;
    </th>,
  ];
  const body = [];
  if (Array.isArray(nominaList)) {
    nominaList.forEach(item => {
      const objColorStatus = {
        fontcolor: item.fontcolor,
        color: item.background,
        textStatus: item.status,
        status_label: item.status_label,
      };

      const statuscolors = getColorStatus(objColorStatus);
      const textMonth = isNaN(parseInt(item.payment_month))
        ? '-'
        : monthNames[parseInt(item.payment_month) - 1];
      body.push(
        <tr key={'payroll' + item.PS_ID}>
          <td className='text-start col-2' style={{ paddingLeft: '10px' }}>
            {item.description}
          </td>
          <td className='text-start col-2'>
            <b style={{ color: '#005DBF' }}>{item.payment_year}</b>
          </td>
          <td className='text-start col-2'>
            <b style={{ color: '#005DBF' }}>{textMonth}</b>
          </td>
          <td className='text-center col-1'>{item.range}</td>
          <td className='text-center col-2'>{item.employee_number}</td>
          <td className='text-end col-2' style={{ paddingRight: '15px' }}>
            {item?.total_amount !== 0 ? convertMoneyFormat(item?.total_amount) : '-'}
          </td>
          <td className='text-center col-1'>
            <div
              className='rounded-pill p-1'
              style={{
                backgroundColor: item.background,
              }}
            >
              <b style={{ color: item.fontcolor }}>{statuscolors.status_es}</b>
            </div>
          </td>

          <td className='col-1'>
            {item.status !== 'in_process' ? (
              <CustomPopupExtend
                showDetails={!!myPermission?.edit && item.status !== 'failed' && true}
                noHover
                triggerSrc={threeDots}
                showDetailsEvent={() => {
                  setDetails({ show: true, sheet: item.PS_ID, status: item.status, elem: item });
                }}
                textDetails='Ver Detalle'
                showDelete={
                  !!myPermission?.edit &&
                  ['loaded', 'failed', 'loaded_settlement'].includes(item.status)
                }
                deleteEvent={() =>
                  editData({
                    id: item.PS_ID,
                    see_year: item.payment_year,
                    see_month: textMonth,
                    status: 'cancelled',
                  })
                }
                deleteText='Eliminar'
                showEdit={
                  !!myPermission?.edit &&
                  (item?.status === 'loaded' ||
                    item?.status === 'rejected' ||
                    item?.status === 'loaded_settlement')
                }
                editClickEvent={() => handleEdit(item, textMonth)}
                editText={
                  item?.status === 'loaded' || item?.status === 'rejected'
                    ? 'Enviar a contabilidad'
                    : 'Enviar a liquidación'
                }
                extraButtons={
                  ['approved', 'processed'].includes(item?.status)
                    ? [
                      {
                        text: 'Provisiones',
                        icon: iconProvisions,
                        class: CustomPopupStyles.popUpGeneric,
                        event: () => {
                          history.push({
                            pathname: '/nomina/provisionesDeNomina',
                            state: { IdPayroll: item.PS_ID },
                          });
                        },
                      },
                    ]
                    : item.status === 'failed'
                      ? [
                        {
                          text: 'Ver fallo',
                          icon: iconProvisions,
                          class: CustomPopupStyles.popUpGeneric,
                          event: () => {
                            message(
                              'warning',
                              'Intenta de nuevo',
                              item?.cron_log_message,
                              undefined,
                              true,
                            );
                          },
                        },
                      ]
                      : []
                }
              />
            ) : (
              <div style={{ width: '24px' }}>&nbsp;</div>
            )}
          </td>
        </tr>,
      );
    });
  }
  let optionConsumptionCenter = [{ value: '', label: 'Seleccione área', name: 'area' }];
  if (Array.isArray(counter.consumptionCenterReducer.consumption_center)) {
    counter.consumptionCenterReducer.consumption_center.forEach(item => {
      optionConsumptionCenter.push({
        value: item.id,
        label: item.description,
      });
    });
  }

  const monthOptions = [
    { label: 'Seleccionar...', value: '' },
    { label: 'Enero', value: 1 },
    { label: 'Febrero', value: 2 },
    { label: 'Marzo', value: 3 },
    { label: 'Abril', value: 4 },
    { label: 'Mayo', value: 5 },
    { label: 'Junio', value: 6 },
    { label: 'Julio', value: 7 },
    { label: 'Agosto', value: 8 },
    { label: 'Septiembre', value: 9 },
    { label: 'Octubre', value: 10 },
    { label: 'Noviembre', value: 11 },
    { label: 'Diciembre', value: 12 },
  ];
  const renderPrincipal = (
    <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: '3rem' }}>
      {(isLoading || statusPayrollLoader || loadImbalance) && (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      )}

      <div className='d-flex'>
        <h1 className={tableStyles.title}>Planillas de nómina</h1>
      </div>

      {!!myPermission?.read && (
        <>
          <Row className='align-items-end'>
            <Col xs={2}>
              <p className={tableStyles.crudModalLabel}>Mes</p>
              <SelectComponent
                value={monthOptions.filter(x => x.value === filters.month)}
                styles={customSelectNewDark}
                placeholder={'Seleccionar...'}
                key={'state' + trigger}
                onChange={e => {
                  setFilter({
                    ...filters,
                    month: e.value,
                    page: 1,
                  });
                  setTrigger(trigger + 1);
                }}
                options={monthOptions}
              ></SelectComponent>{' '}
            </Col>
            <Col xs={3} className='d-flex gap-2'>
              <div className=''>
                <p className={tableStyles.crudModalLabel}>Año</p>
                <input
                  className='register-inputs'
                  type='number'
                  value={filters.year}
                  onChange={e =>
                    setFilter({
                      ...filters,
                      year: e.target.value,
                      page: 1,
                    })
                  }
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      searchFilters();
                    }
                  }}
                ></input>
              </div>
              <img
                className='hoverPointer align-self-end'
                alt='iconSearch'
                style={{}}
                src={Lupa}
                height='24px'
                onClick={e => searchFilters(e)}
              />
            </Col>

            <Col xs={7} className='align-self-end'>
              {!!myPermission?.create && (
                <div className='d-flex justify-content-end gap-2'>
                  <span
                    className='darkGray fw-bold align-self-center'
                    style={{ color: '#58595B', fontSize: '13px' }}
                  >
                    Generar planilla{' '}
                  </span>
                  <div
                    className={Styles.btnpreviw}
                    style={{ width: '31px', height: '31px' }}
                    onClick={() => setShowModal({ show: true })}
                  ></div>
                </div>
              )}
            </Col>
          </Row>
          <div></div>
          <GenericTable headers={header} dark={true}>
            {body}
          </GenericTable>
        </>
      )}

      <ImbalanceWarningModal data={resultImbalace.results} show={showImbalanceModal} onCloseModal={handleCloseImbalanceModal} />

      <ModalNew
        show={showModal.show}
        onHide={() => {
          setShowModal({ show: false });
          setGenerate({
            entity_account: counter.loginReducer.currentAccount.id,
            entity_name: counter.loginReducer.currentAccount.name,
            start_date: '',
            end_date: '',
            payment_date: '',
            created_by: counter.loginReducer.user_data.id,
          });
        }}
        title={'Generar planilla de nómina'}
        btnYesEvent={() => {
          btnGenerate(generate);
        }}
        btnYesName={'Generar'}
        btnYesDisabled={
          !!myPermission?.create &&
          (!!(generate.start_date === '') ||
            !!(generate.end_date === '') ||
            !!(generate.payment_date === ''))
        }
        btnNoEvent={() => {
          setShowModal({ show: false });
          setGenerate({
            entity_account: counter.loginReducer.currentAccount.id,
            entity_name: counter.loginReducer.currentAccount.name,
            start_date: '',
            end_date: '',
            payment_date: '',
            created_by: counter.loginReducer.user_data.id,
          });
        }}
        btnNoName={'Cancelar'}
        size={'500'}
      >
        {isLoading2 && (
          <div className='loading'>
            <Loader type='Oval' color='#003f80' height={100} width={100} />
          </div>
        )}
        <Row className='d-flex'>
          <Col xs={6}>
            <label className={tableStyles.crudModalLabel}>Fecha de inicio</label>
            <input
              className='register-inputs'
              style={{ color: '#7FADDE', fontWeight: '700' }}
              type='date'
              value={generate.start_date}
              onChange={e =>
                setGenerate({
                  ...generate,
                  start_date: e.target.value,
                })
              }
              disabled={false}
            ></input>
          </Col>
          <Col xs={6}>
            <label className={tableStyles.crudModalLabel}>Fecha de fin</label>
            <input
              className='register-inputs'
              style={{ color: '#7FADDE', fontWeight: '700' }}
              type='date'
              value={generate.end_date}
              onChange={e =>
                setGenerate({
                  ...generate,
                  end_date: e.target.value,
                })
              }
              disabled={false}
            ></input>
          </Col>
        </Row>

        <Row className='d-flex' style={{ marginBottom: '12px' }}>
          <Col xs={6}>
            <label className={tableStyles.crudModalLabel}>Fecha de pago</label>
            <input
              className='register-inputs'
              style={{ color: '#7FADDE', fontWeight: '700' }}
              type='date'
              value={generate.payment_date}
              onChange={e =>
                setGenerate({
                  ...generate,
                  payment_date: e.target.value,
                })
              }
              disabled={false}
            ></input>
          </Col>
        </Row>
      </ModalNew>

      <div className={paginationStyles.wrapper}>
        <p className={` ${paginationStyles.paginationText} darkGray`}>
          pag. {filters.page}
          {' de '}
          {Math.ceil(nominaTotal / filters.perpage)
            ? Math.ceil(nominaTotal / filters.perpage)
            : '1'}{' '}
          ({' ' + nominaTotal}
          {' encontrados'})
        </p>
        <Pagination
          activePage={filters.page}
          itemsCountPerPage={filters.perpage}
          totalItemsCount={nominaTotal}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
          itemClassPrev={`${paginationStyles.itemClassPrev} `}
          itemClassNext={`${paginationStyles.itemClassNext} `}
          itemClassFirst={`${paginationStyles.itemClassFirst} `}
          itemClassLast={`${paginationStyles.itemClassLast} `}
          itemClass={paginationStyles.itemClass}
        />
      </div>
    </div>
  );

  return details.show ? (
    <DetailPayrollTemplate
      payroll_sheet={details.sheet}
      details={details}
      setDetails={setDetails}
      showModal={showModal}
      setTrigger={setTrigger}
      trigger={trigger}
      setShowModal={setShowModal}
    />
  ) : (
    renderPrincipal
  );
}
export default GeneratePayrollList;
