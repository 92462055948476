import { API_VERSION, convertFilterToString, customSwaltAlert, message, URL_GATEWAY } from "../helpers";
import { ON_CHECK_CONTRACT_STATUS } from "./actionTypes";

export const checkContractStatus = (status, contractsAccounts) => dispatch => {
  dispatch({
    type: ON_CHECK_CONTRACT_STATUS,
    status,
    contractsAccounts
  });
};

export const getCheckContractStatus = ({ params, url = '/payroll/contracts-expiring-soon/', history = undefined }) => (dispatch, getState) => {
  dispatch({
    type: ON_CHECK_CONTRACT_STATUS,
    loading: true,
  });
  let filters = convertFilterToString(params);
  const store = getState()
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}${url}?${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      const { results, contracts, success } = res;
      if (success || results?.data?.length || results?.length) {
        if (store.checkContractStatusReducer.status) {
          dispatch({
            type: ON_CHECK_CONTRACT_STATUS,
            status: false,
          });
        }
      } else {
        if (contracts?.length) {
          const result = contracts?.reduce((acc, contract) => {
            const entityName = contract.entityAccountName;
            const entity = acc.find(item => item.entity === entityName);
            if (!entity) {
              acc.push({
                entity: entityName,
                contracts: [
                  {
                    cntId: contract?.cntId,
                    cntExpire: contract?.cntExpire,
                    candidateName: contract?.candidateName,
                  },
                ],
              });
            } else {
              entity.contracts.push({
                cntId: contract?.cntId,
                cntExpire: contract?.cntExpire,
                candidateName: contract?.candidateName,
              });
            }
            return acc;
          }, []);
          customSwaltAlert({
            icon: 'warning',
            title: res.message,
            ...(history ? {
              text: '¿Deseas ir a gestionarlos?',
              confirmButtonText: 'Si',
              cancelButtonText: 'Permanecer aqui',
              showCancelButton: true,
            } : {
              showCancelButton: false,
              confirmButtonText: 'Aceptar',
            }),

            customClass: {
              denyButton: `ordBtnPrimaryModuleAdmin`,
            },
          }).then(result => {
            if (result.isConfirmed) {
              if (history) {
                history.push(`/nomina/contratos/`);
              }
            }
          });
          dispatch({
            type: ON_CHECK_CONTRACT_STATUS,
            status: true,
            contractsAccounts: result,
          });
        }
      }
      dispatch({
        type: ON_CHECK_CONTRACT_STATUS,
        loading: false,
      });
    })
    .catch(err => {
      dispatch({
        type: ON_CHECK_CONTRACT_STATUS,
        loading: false,
      });
      return message('error', 'Error', err?.message ? err?.message : 'Ha ocurrido un error');
    });
};